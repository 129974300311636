import './Booking.css';
import Header from '../Header/Header'
import ArrowButton from '../ArrowButton/ArrowButton'
import Calendar from 'react-calendar';
import '../AppointmentSettings2/MyCalendar.css';
import Hours from '../Hours/Hours';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { analytics, db } from '../../config/firebase-config';
import { collection, where, query, getDocs, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import TherapyFile from '../TherapyType/TherapyFile';
import Alert from '../Allert/Alert';
import { useStateValue } from '../StateProvider';
import { Timestamp } from "firebase/firestore";
import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';
import moment from 'moment-timezone';
import { auth, app } from '../../config/firebase-config';
import { useNavigate } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions'; // Import getFunctions and httpsCallable
import { logEvent } from 'firebase/analytics';
import SpeechBubble from "../SpeechBubble/SpeechBubble";
import { ReactComponent as Avatar } from '../../SVGs/Avatar.svg';
import Joyride, { CallBackProps, STATUS, ACTIONS } from 'react-joyride';


/* function transformDateFormats(dates) {
  return dates?.map(dateStr => {
    // Parse the date string into a Date object
    const date = new Date(Date.parse(dateStr));

    // Extract the year, month, and day, and ensure month and day are two digits
    const year = date.getFullYear();
    // Adding 1 because getMonth() returns 0-11
    const month = (`0${date.getMonth() + 1}`).slice(-2);
    const day = (`0${date.getDate()}`).slice(-2);

    // Format the date as 'YYYY-MM-DD'
    return `${year}-${month}-${day}`;
  });
} */

const formatDate = (date) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString('sr-Latn', options);
};


// Function to format the time
const formatTime = (date) => {
  const options = { hour: '2-digit', minute: '2-digit' };
  return date.toLocaleTimeString('sr-RS', options);
};


function mapDaysToNumbers(daysArray) {
  const dayToNumberMap = {
    'PON': 1,
    'UTO': 2,
    'SRE': 3,
    'ČET': 4,
    'PET': 5,
    'SUB': 6,
    'NED': 7
  };

  return daysArray?.map(day => dayToNumberMap[day] || 0); // Returns 0 if the day is not found, you can adjust this behavior as needed
}

function transformDateFormats(dateStrings) {
  return dateStrings?.map(dateStr => {
    // Split the date string to extract components
    const parts = dateStr.match(/(\w+), (\w+) (\d+), (\d+)/);
    if (!parts) return null;

    // Map month names to numbers
    const months = {
      'January': 0, 'February': 1, 'March': 2, 'April': 3, 'May': 4, 'June': 5,
      'July': 6, 'August': 7, 'September': 8, 'October': 9, 'November': 10, 'December': 11
    };

    // Create a UTC date
    const year = parseInt(parts[4], 10);
    const month = months[parts[2]]; // Convert month name to number
    const date = parseInt(parts[3], 10);
    const utcDate = new Date(Date.UTC(year, month, date, 0, 0, 0, 0));

    return utcDate.getTime(); // Convert to timestamp
  });
}


function Booking() {
  const navigate = useNavigate();
  const [{ basket }, dispatch] = useStateValue();
  const [adress, setAddress] = useState();
  const { name } = useParams();
  const [therapystAccount, setTherapystAccount] = useState('');
  const today = new Date();
  const [loading, setLoading] = useState(true);
  const [chossenHour, setChossenHour] = useState(null);
  const [GUItype, setGUItype] = useState([]);
  const [clientData, setClientData] = useState({});
  const [therapystEmail, setTherapystEmail] = useState();
  const [clientEmail, setClientEmail] = useState();
  const [emailArray, setEmailArray] = useState();
  const [price, setPrice] = useState();
  const [account2, setAccount2] = useState('');
  const [description, setDescription] = useState('');
  const [account, setAccount] = useState();
  let [hours, setHour] = useState([]);
  const [run, setRun] = useState(false);
  const newDate = new Date();

  const functions = getFunctions(app);
  const sendEmail = httpsCallable(functions, 'sendEmail');

  useEffect(() => {
    if (!auth.currentUser) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (!basket[0].bookingAvatarSeen) {
      setRun(true);
      try {
        const userRef = doc(db, "Clients", auth.currentUser.uid);
        const updateAvatar = async () => {
          await updateDoc(userRef, {
            bookingAvatarSeen: true
          }
          );
        }
        updateAvatar();
      }
      catch (e) {
        console.log(e)
      }
    }
  }, []);



  const [steps, setSteps] = useState([
    {
      target: '.react-calendar',
      content:
        <div className="AvatarContainer">
          <Avatar />
          <SpeechBubble style={{}}>
            <p>
              Kalendar ti omogućava da izabereš datum za zakazivanje termina. Datumi koji su označeni crvenom bojom su oni kada ponuđač nije dostupan.
            </p>

          </SpeechBubble>
        </div>,
      disableBeacon: true,
      styles: {
        options: {
          arrowColor: 'hsl(147, 50%, 47%,0)',
          backgroundColor: "hsl(165, 12%, 69%)",
          beaconSize: 36,
          overlayColor: 'rgba(0, 0, 0, 0.5)',
          primaryColor: 'rgba(123, 199, 191, 0.9)',
          spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
          textColor: '#333',
          width: "min(30rem, 90vw,90vh)",
          zIndex: 100,
          offset: 100,
          arrowSize: 0,
        },

        buttonBack: {
          color: 'black', // This will apply the color black to the text of the back button
        },

      }
    },
    {
      target: '.PayContainer',
      content:
        <div className="AvatarContainer" style={{ position: "relative", top: "3rem" }}>
          <Avatar />
          <SpeechBubble style={{ position: "relative", bottom: "5rem", left: "-1rem" }}>
            <p>
              Ovde se nalaze infromacije o načinu plaćanja.
              <br />
              <b>Ne moraš da ih zapamtiš!</b> Nakon što zakažeš termin, svim informacijama ćeš moći da pristupiš u odeljku <em>Termini</em> na svom profilu.
            </p>
          </SpeechBubble>
        </div>,

      styles: {
        options: {
          arrowColor: '#fff',
          backgroundColor: "hsl(165, 12%, 69%)",
          beaconSize: 36,
          overlayColor: 'rgba(0, 0, 0, 0.5)',
          primaryColor: 'rgba(123, 199, 191, 0.9)',
          spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
          textColor: '#333',
          width: "min(30rem, 90vw,90vh)",
          zIndex: 100,
          offset: 100
        },

        buttonBack: {
          color: 'black', // This will apply the color black to the text of the back button
        },

      }
    },]);

  const handleJoyrideCallback = (data) => {
    const { action, index, type, status } = data;

    if (index === 0 && type === 'step:before') {
      // Log 'tutorial_begin' event
      logEvent(analytics, 'tutorial_begin', {
        tutorial_location: auth.currentUser ? 'Feed2' : 'Feed1'
      });
    }

    // Tutorial Complete
    if (status === 'finished') {
      // Log 'tutorial_complete' event
      logEvent(analytics, 'tutorial_complete', {
        tutorial_location: auth.currentUser ? 'Feed2' : 'Feed1'
      });
    }

    // Tutorial Closed
    if (status === 'skipped' || (status === 'closed' && index < steps.length - 1)) {
      // Log 'tutorial_closed' event, indicating the tutorial was closed before completion
      logEvent(analytics, 'feed_tutorial_closed', {
        step_index: index + 1, // Indicates the last step viewed before closing
        reason: status, // 'skipped' or 'closed'
        tutorial_location: auth.currentUser ? 'Feed2' : 'Feed1'
      });
    }

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || action === ACTIONS.CLOSE) {
      setRun(false); // Stops the tour
    }
    // You can add more conditions here based on your requirements.
  };


  const sendNotification = async (receiverToken, title, body) => {
    
    const sendNotificationFunction = httpsCallable(functions, 'sendNotification');

    try {
      const result = await sendNotificationFunction({ receiverToken, title, body });
      console.log('Notification sent:', result.data);
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };




  const GoogleServiceArray = [
    "foto.bloom8@gmail.com",
    "kaca.lukic.2000@gmail.com",
    "morlovic976@gmail.com",
    "mihailovicdjordje12@gmail.com",
    "stojanovicandjela000@gmail.com",
    "nstanic932@gmail.com",
    "milica108.stevanovic@gmail.com",
    "szandraseres47@gmail.com",
    "iiskranikolic@gmail.com",
    "komatina.nenad@gmail.com",
    "jelenaa546@gmail.com",
    "ramadaniamor04@gmail.com",
    "aleksistankovic21@gmail.com",
    "nikolabozanovic86@gmail.com",
    "jelenaalimpijevic01@gmail.com",
    "lenna.markovic@gmail.com",
    "sunadja01@gmail.com",
    "milicakeca0@gmail.com",
    "tamaragajic20@gmail.com",
    "puricamila1@gmail.com",
    "aleksandarveljkovic181@gmail.com",
    "ivanamikavica@gmail.com",
    "nikoletapajic@yahoo.com",
    "anavukic2002@gmail.com",
    "momomedjedzavicaj@gmail.com",
    "jevticmilicagrf@gmail.com",
    "pavlovic.jovanapavlovic.jovana@gmail.com",
    "krsticksenija9@gmail.com",
    "nvnavr@gmail.com",
    "jelena.gajic.so@gmail.com",
    "marija.juzbasic@gmail.com",
    "lukiicb03@gmail.com",
    "zrn.primus@gmail.com",
    "ficajovic@gmail.com",
    "jovanajovicevic5@gmail.com",
    "pedja.jovicic68@gmail.com",
    "trajkovski.rada@gmail.com",
    'cpejdic@gmail.com',
    'jpejdic@gmail.com',
    'smartbasic@gmail.com',
    "ivana.pejdic1@gmail.com",
    "evawellbeing@gmail.com",
    'marijailicc19@gmail.com',
    "una.pirusic@yahoo.com",
    "aleksandraa.maksimoviic@gmail.com",
    "sladjamiljesic@gmail.com",
    "lidijakocic3@gmail.com",
    'kovacevicgeorg0@gmail.com',
    "bubaalek@gmail.com",
    "adinamajdlik1@gmail.com",
    "szilvianagy062@gmail.com",
    "nikolichnatasha@gmail.com",
    "srdjan.timotijevic@gmail.com"

  ]

  const formattedDate = today.toLocaleDateString('en-US', {
    weekday: 'long', // long format for day (e.g., "Friday")
    year: 'numeric', // numeric format for year (e.g., "2023")
    month: 'long',   // long format for month (e.g., "August")
    day: 'numeric'   // numeric format for day (e.g., "11")
  });
  let [date, setDate] = useState(formattedDate);
  let [dateObject, setDateObject] = useState(today);
  let [serbianDate, setSerbianDate] = useState(moment(today).tz("Europe/Belgrade"));
  let [formattedSerbianDate, setFormatedSerbianDate] = useState(serbianDate.format('YYYY-MM-DDTHH:mm:ss'));
  let [serbianDate2, setSerbianDate2] = useState(moment(today.setHours(today.getHours() + 1)).tz("Europe/Belgrade"));
  let [formattedSerbianDate2, setFormatedSerbianDate2] = useState(serbianDate2.format('YYYY-MM-DDTHH:mm:ss'));




  useEffect(() => {
    setSerbianDate(moment(dateObject).tz("Europe/Belgrade"))
    setSerbianDate2(moment(dateObject.setHours(dateObject.getHours() + 1)).tz("Europe/Belgrade"))

  }, [dateObject]);

  useEffect(() => {
    setFormatedSerbianDate(serbianDate.format('YYYY-MM-DDTHH:mm:ss'))

  }, [serbianDate]);
  useEffect(() => {
    setFormatedSerbianDate2(serbianDate2.format('YYYY-MM-DDTHH:mm:ss'))

  }, [serbianDate2]);
  const englishDay = date.slice(0, 3).toUpperCase();
  const noDot = name.replace(".", " ");
  function capitalizeFirstLetterOfEachWord(str) {
    return str.toLowerCase().split(' ').map(function (word) {
      return word.charAt(0).toUpperCase() + word.substring(1);
    }).join(' ');
  }
  const headerName = capitalizeFirstLetterOfEachWord(noDot);

  const parts = headerName.split(' ');
  const headerN = parts[0]; // First name
  let headerS = '';
  if (parts.length > 1) {
    // Join the remaining parts as the surname
    headerS = parts.slice(1).join(' ');
  }

  useEffect(() => {

    const fetchClientData = async () => {
      const q = query(
        collection(db, 'Clients'),
        where('name', '==', headerN),
        where('surname', '==', headerS)
      );

      try {

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          // Assuming there's only one document that matches, or you're only interested in the first match
          setClientData(doc.data());
        });
      } catch (error) {

        console.error("Error fetching client data:", error);
      }
    };

    fetchClientData();
  }, []);

  function translateDayToSerbian(englishDay) {
    const translation = {
      'MON': 'PON', // Monday
      'TUE': 'UTO', // Tuesday
      'WED': 'SRE', // Wednesday
      'THU': 'ČET', // Thursday
      'FRI': 'PET', // Friday
      'SAT': 'SUB', // Saturday
      'SUN': 'NED'  // Sunday

    };

    return translation[englishDay] || 'Invalid day';
  }

  const serbianDay = translateDayToSerbian(englishDay);

  async function getHours(date, serbianDay, HeaderN, HeaderS) {

    const q = query(
      collection(db, 'Clients'),
      where('name', '==', HeaderN),
      where('surname', '==', HeaderS)
    );

    const clientSnapshot = await getDocs(q);

    if (clientSnapshot.empty) {
      console.log("No matching clients found.");
      return null;
    }

    // Assume only one matching client and use the first doc's reference
    const clientDocRef = clientSnapshot.docs[0].ref;
    const clientData = clientSnapshot.docs[0].data();

    if (clientData && clientData.email) {
      setTherapystEmail(clientData.email);
    } else {
      console.log("Email not found in client data.");
    }


    // Check the SpecificDates subcollection first
    const specificDatesRef = doc(clientDocRef, 'SpecificDates', date);
    const specificDateSnapshot = await getDoc(specificDatesRef);

    if (specificDateSnapshot.exists()) {
      return specificDateSnapshot.data().hours;
    }

    // If we reach here, check the WorkingTime subcollection
    const workingTimeRef = doc(clientDocRef, 'WorkingTime', serbianDay);
    const workingTimeSnapshot = await getDoc(workingTimeRef);

    if (workingTimeSnapshot.exists()) {
      return workingTimeSnapshot.data().hours;
    }

    console.log("No hours found for the given dates.");
    return null;
  }
  // q: i want to fetch 

  async function fetchHours() {
    try {
      const resultHours = await getHours(date, serbianDay, headerN, headerS);
      const clientsRef = collection(db, "Clients");
      const clientQuery = query(clientsRef, where("name", "==", headerN), where("surname", "==", headerS));
      const appointmentsArray = [];
      const filterHours = [];
      const targetDate = Timestamp.fromDate(new Date(date)); // Convert your target date to Firestore Timestamp

      const clientSnapshot = await getDocs(clientQuery);

      if (clientSnapshot.empty) {
        console.log('No matching clients found.');
        return;
      }

      for (const clientDoc of clientSnapshot.docs) {
        const appointmentsRef = collection(db, `Clients/${clientDoc.id}/Appointments`);
        const appointmentsSnapshot = await getDocs(appointmentsRef);

        appointmentsSnapshot.forEach(appointmentDoc => {
          const appointmentData = appointmentDoc.data();
          if (appointmentData.date && appointmentData.date.toDate().toDateString() === new Date(date).toDateString()) {
            appointmentsArray.push(appointmentData);
          }
        });
      }
      appointmentsArray.forEach(appointment => {
        filterHours.push(appointment.date.toDate().getHours());
      });

      const formattedFilterHours = filterHours.map(hour => hour < 10 ? `0${hour}:00` : `${hour}:00`);
      if (resultHours) {
        const resultHoursFiltered = resultHours.filter(hour => !formattedFilterHours.includes(hour));
        setHour(resultHoursFiltered);
      } else {
        console.error("getHours returned null");
        setHour([]); // Handle the null case
      }

      setLoading(false);
    } catch (error) {
      console.error("Error in fetchHours: ", error);
      setLoading(false);
    }
  }






  async function fetchData() {
    const clientsRef = collection(db, "Clients");
    const therapystQuery = query(clientsRef, where("name", "==", headerN), where("surname", "==", headerS));
    const querySnapshot = await getDocs(therapystQuery);
    if (!querySnapshot.empty) {
      // assuming there's only one matching document, get the first one
      const therapystData = querySnapshot.docs[0].data();
      setTherapystAccount(therapystData.account);
      setAddress(therapystData.address);
      setGUItype(therapystData.TherapyTypes);
      setPrice(therapystData.price);
      therapystData.account2 && setAccount2(therapystData.account2);
      therapystData.description2 && setDescription(therapystData.description2);
      if (therapystData.account) {
        setAccount(therapystData.account);
      } else {
        setAccount(null);
      }

    } else {
      console.log("No such document!");
    }

  }

  useEffect(() => {


    fetchData();
    fetchHours();
  }, [date]);


  function onCheckBoxClick(e) {

    let element = e.target;
    if (!element.firstChild.classList?.contains('activeCheckBox')) {
      document.querySelectorAll('.activeCheckBox').forEach(element => { element.classList.remove('activeCheckBox') });
      element.firstChild.classList?.add('activeCheckBox');
      setChossenHour(parseInt(element.firstChild.nextSibling?.textContent));
    } else {
      element.firstChild.classList.remove('activeCheckBox');
      setChossenHour(null);
    }

  }
  useEffect(() => {
    const currentYear = dateObject.getFullYear();
    const currentMonth = dateObject.getMonth();
    const currentDay = dateObject.getDate();

    // Step 2: Set the new hour value
    const newHour = chossenHour; // Replace 'hour' with your desired hour value (0-23)

    // Step 3: Create a new Date object with the updated values
    const updatedDateObject = new Date(currentYear, currentMonth, currentDay, newHour, 0, 0);
    setDateObject(updatedDateObject);

  }, [chossenHour]);


  /* function onTherapyTypeClick(e) {
    const element = e.target;
    if (!element.classList.contains('activeCheckBox2')) {
      document.querySelectorAll('.activeCheckBox2').forEach((ele) => ele.classList.remove('activeCheckBox2'))
      element.classList.add('activeCheckBox2');
      setType(element.nextElementSibling.textContent);
    } else {
      element.classList.remove('activeCheckBox2');
      setType((prev) => prev.empty ? prev.filter(el => el !== element.nextElementSibling.textContent):[]);
    }
  } */

  async function addInTherapystBase(meetLink = null, uniqueId) {
    const q = query(collection(db, "Clients"), where("name", "==", headerN), where("surname", "==", headerS));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach(async (document) => {
      let customDocName = basket[0].name + ' ' + basket[0].surname;
      const postsRef = collection(db, "Clients", document.id, "Appointments");
      let postDocRef = doc(postsRef, customDocName);

      if (!postDocRef.empty) {
        // Document with same name exists, append a unique identifier
        // Example of using a timestamp
        customDocName += `_${uniqueId}`;
        postDocRef = doc(postsRef, customDocName);
      }
      dateObject.setHours(chossenHour);

      await setDoc(postDocRef, {
        date: Timestamp.fromDate(dateObject),
        meetLink: meetLink,
        therapist: therapystEmail,
        price: price,
        account: account,
        ended: false,
        account2: account2,
        description2: description,
        cancelled: false
      });


      try {
        const emailData2 = {
          to: basket[0].email, // Replace with the recipient's email address
          subject: 'Nova zakazana EVA sesija',
          messageBody: `
                      <b>Imate novi zakazan termin sa ponuđačem:</b> <a href="https://evawellbeing.app/profile/${name}">${headerN + ' ' + headerS}</a>
  <br><br>
  <b>Datum:</b> ${formatDate(dateObject)} 
  <br><br>
  <b>Vreme:</b> ${formatTime(dateObject)} 
  <br><br>
  <b>Link ka sastanku:</b> <a href=${meetLink}>Google meet link</a>
  <br><br>
  <b>Cena:</b>${price}</a>
  <br><br>  
  <b>Račun za uplatu:</b>${account ? account : '/'}</a>
  <br><br>  
  <b>IBAN:</b>${account2 ? account2 : '/'}</a>
  <br><br>  
  <b>Poruka ponuđača:</b>${description ? description : '/'}</a>
  <br><br>
  <b>Link ka aplikaciji:</b> <a href="https://evawellbeing.app/">Eva Wellbeing</a>
`          };
        const result = await sendEmail(emailData2);
        console.log('Email sent successfully:', result.data);
      } catch (error) {
        console.error("Error sending email:", error);
      }
      try {
        const emailData = {
          to: therapystEmail, // Replace with the recipient's email address
          subject: 'Nova zakazana EVA sesija',
          messageBody: `
                      <b>Imate novi zakazan termin sa korisnikom:</b> <a href="https://evawellbeing.app/profile/${basket[0].name.toLowerCase() + '.' + basket[0].surname.toLowerCase()}">${basket[0].name + ' ' + basket[0].surname}</a>
  <br><br>
  <b>Datum:</b> ${formatDate(dateObject)} 
  <br><br>
  <b>Vreme:</b> ${formatTime(dateObject)} 
  <br><br>
  <b>Link ka sastanku:</b> <a href=${meetLink}>Google meet link</a>
  <br><br> 
  <b>Link ka aplikaciji:</b> <a href="https://evawellbeing.app/">Eva Wellbeing</a>
`          };
        const result = await sendEmail(emailData);
        console.log('Email sent successfully:', result.data);
      } catch (error) {
        console.error("Error sending email:", error);
      }

      if (document.data().token && Array.isArray(document.data().token)) {
        document.data().token.forEach(token => {
          let receiverToken = token;
          let title = 'EVA termin'
          let body = 'Imate novi zakazan termin'
          sendNotification(receiverToken, title, body);
        });
      }

    });
    navigate(`../../profile/${basket[0].name.toLowerCase()}.${basket[0].surname.toLowerCase()}/appointments`);
    alert('Uspešno ste zakazali termin');
  }

  async function addInClientBase(meetLink = null, uniqueId) {

    // Log the values in basket[0] to ensure they are as expected
    const q = query(collection(db, "Clients"), where("name", "==", basket[0].name), where("surname", "==", basket[0].surname));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach(async (document) => {
      let customDocName = headerN + ' ' + headerS;
      const postsRef = collection(db, "Clients", document.id, "Appointments");
      let postDocRef = doc(postsRef, customDocName);

      if (!postDocRef.empty) {
        // Document with same name exists, append a unique identifier
        // Example of using a timestamp
        customDocName += `_${uniqueId}`;
        postDocRef = doc(postsRef, customDocName);
      }

      dateObject.setHours(chossenHour);

      // Log the data being set in Firestore
      const docData = {
        date: Timestamp.fromDate(dateObject),
        meetLink: meetLink,
        price: price,
        account: account,
        ended: false,
        account2: account2,
        description2: description,
        cancelled: false
      };

      await setDoc(postDocRef, docData);

    });

  }


  function onSubmitClick() {
    if (chossenHour) {
      try {
        const uniqueId = new Date().getTime();
        addInTherapystBase(null, uniqueId);
        addInClientBase(null, uniqueId);
        logEvent(analytics, 'appointment_concluded', {
          therapist_name: headerName
        })
        dispatch({
          type: "UPDATE_JUST_BOOKED",
          justBooked: true
        });
        navigate(`../../profile/${basket[0].name.toLowerCase()}.${basket[0].surname.toLowerCase()}/appointments`)
      }
      catch { alert('Došlo je do greške prilikom zakazivanja termina, pokušajte ponovo') }


    } else { alert('Došlo je do greške prilikom zakazivanja termina, pokušajte ponovo') }
  }


  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, "Clients"), where("name", "==", basket[0].name), where("surname", "==", basket[0].surname));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((document) => {
        setClientEmail(document.data().email);
      });
    }
    fetchData();
  }, [date]);

  useEffect(() => {
    setEmailArray([therapystEmail, clientEmail])

  }, [date]);


  const onSuccess = (response) => {
    console.log('onSuccess triggered', response);
    if (response && response.access_token) {
      createMeeting(response.access_token);
    } else {
      console.error('Failed to authenticate');
    }
  };

  const signIn = useGoogleLogin({
    clientId: '1062402387067-ke3hv9ln5sdhsm1ul3g6edpgjbjhnevp.apps.googleusercontent.com',
    onSuccess,
    onFailure: (error) => {
      console.error("Google Login Error:", error);
    },
    scope: 'https://www.googleapis.com/auth/calendar', // Only request calendar access
  });

  const createMeeting = (token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };

    const eventData = {
      summary: 'EVA sesija',
      start: {
        dateTime: formattedSerbianDate,
        timeZone: 'Europe/Belgrade'
      },
      end: {
        dateTime: formattedSerbianDate2,
        timeZone: 'Europe/Belgrade'
      },
      attendees: emailArray.map(email => ({ email })),
      conferenceData: {
        createRequest: {
          requestId: auth.currentUser.uid,
          conferenceSolutionKey: {
            type: "hangoutsMeet"
          }
        }
      }
    };

    axios.post('https://www.googleapis.com/calendar/v3/calendars/primary/events?conferenceDataVersion=1', eventData, config)
      .then(async (response) => {
        console.log('Meeting created:', response.data);

        const meetLink = response.data.hangoutLink;
        const uniqueId = new Date().getTime();
        addInTherapystBase(meetLink, uniqueId);
        addInClientBase(meetLink, uniqueId);

        const sendEmailFunction = httpsCallable(functions, 'sendEmailFunction');
        await sendEmailFunction({ emailArray: emailArray, meetLink: meetLink, formattedSerbianDate: formattedSerbianDate });
      })
      .catch(error => {
        console.log('Došlo je do greške prilikom zakazivanja termina, pokušajte ponovo.');
      });
  };

  async function onClickButton2() {
    if (chossenHour) {
      try {
        logEvent(analytics, 'appointment_concluded', {
          therapist_name: headerName
        });
        dispatch({
          type: "UPDATE_JUST_BOOKED",
          justBooked: true
        });
        signIn();
      } catch {
        console.log('Došlo je do greške prilikom zakazivanja termina, pokušajte ponovo');
      }
    } else {
      alert('Morate izabrati termin.');
    }
  }

  const disabledDates = (date) => {
    // Use UTC methods to ensure consistency across time zones
    const startOfDayUTC = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

    const todayUTC = new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()));

    const oneMonthFromTodayUTC = new Date(todayUTC);
    oneMonthFromTodayUTC.setUTCMonth(todayUTC.getUTCMonth() + 1);

    // Assuming transformDateFormats correctly handles verbose date strings and converts them to UTC timestamps
    const specificDatesUTC = transformDateFormats(clientData.workingDays?.specificDates);

    const myDays = mapDaysToNumbers(clientData.workingDays?.workingTime);

    const isOutOfRange = startOfDayUTC < todayUTC || startOfDayUTC > oneMonthFromTodayUTC;

    // Adjusting for Sunday = 7 instead of 0, if necessary
    // Note: getUTCDay is used for consistency, though day of the week is not timezone-dependent
    const dayOfWeek = startOfDayUTC.getUTCDay() === 0 ? 7 : startOfDayUTC.getUTCDay();
    const isDayAllowed = myDays?.includes(dayOfWeek);

    // Checking if the date is in the list of specific dates to enable
    const isDateSpecificallyEnabled = specificDatesUTC?.includes(startOfDayUTC.getTime());

    // Date should be disabled if it's out of range, not an allowed day, and not specifically enabled
    return isOutOfRange || !isDayAllowed && !isDateSpecificallyEnabled;
  };


  return (
    <div className='Booking'>
      <Header name={basket[0]?.name} surname={basket[0]?.surname} />
      <div className='BookingContainer'>
        <ArrowButton text='Zakaži termin' onClickEdit={() => navigate("../")} />
        <Calendar tileDisabled={({ date, view }) => view === 'month' && disabledDates(date)} onChange={(value) => {
          setDateObject(value); setDate(value.toLocaleDateString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          }))
        }} />
        <div className='HoursBigContainer'>
          <span className='TFtext'>{hours.length !== 0 ? "Izaberi termin" : <p className='EmptyDayText'>Nema slobodnih termina za ovaj dan </p >}</span>
          {loading ? <p>Loading hours...</p> : <Hours style={{ pointerEvents: "none" }} setCheckBox={onCheckBoxClick} hoursArray={hours?.sort((a, b) => {
            const [hoursA, minutesA] = a.split(':').map(Number);
            const [hoursB, minutesB] = b.split(':').map(Number);

            return (hoursA * 60 + minutesA) - (hoursB * 60 + minutesB);
          })} />}</div>
        {/* <div className='TherapyFileContainer'>
        <span className='TFtext'>Lokacija seanse</span>
        {loading2 ? <p>Loading hours...</p> :<TherapyFile GUItype={GUItype}  setCheckBox = {onTherapyTypeClick}/>}
      </div> */}
        <div className='PayContainer'>
          <h4 className='PayHeader'>Uplatu izvršiti na račun Vašeg izabranog ponuđača <br /><br /><p style={{ fontWeight: 200, fontSize: "0.8rem", color: "hsla(219, 16%, 35%, 1);", }}><b>Informacije ispod ne morate da zapamtite</b> pošto ćete njima moći da pristupite u sekciji <em><b>Termini</b></em> na svom profilu nakon što zakažete termin.</p></h4>
          <div className='PayLabel'>Broj računa</div>
          <div className='PayDescription'>{therapystAccount}</div>
          {clientData && clientData.account2 && clientData.account2.length >= 2 ?
            <>
              <div className='PayLabel'>IBAN(ukoliko ponuđač ne živi u Vašoj državi)</div>
              <div className='PayDescription'>{clientData.account2}</div>
            </> : null}
          {clientData && clientData.description2 && clientData.description2.length >= 2 ?
            <>
              <div className='PayLabel'>Poruka ponuđača:</div>
              <div className='PayDescription'>{clientData.description2}</div>
            </> : null}
          <div className='PayLabel'>Svrha uplate</div>
          <div className='PayDescription'>Vaše ime i prezime</div>
          <Alert signColor='hsla(346, 100%, 50%, 1)' textColor='hsla(346, 100%, 50%, 1)' backgroundColor='hsla(346, 100%, 50%, 0.1)' text='Uplatu je potrebno izvršiti minimum 6h pre zakazane seanse' />
        </div>
        {GUItype && GUItype.includes('Uživo') ?
          <div className='PayContainer'>
            <h4 className='PayHeader'>Adresa</h4>
            <div className='PayDescription'>{adress}</div>
          </div>
          : null}
        <button onClick={/* !GoogleServiceArray.includes(clientEmail) ? onSubmitClick : */ onClickButton2} className='PayButton'>Rezerviši termin</button>
        <div>
        </div>
      </div>
      <Joyride
        continuous
        run={run} // Ensure this is set to true to start automatically
        steps={steps}
        callback={handleJoyrideCallback}
        disableOverlayClose={true}

        locale={{
          back: 'Nazad', // Unicode for right arrow
          next: 'Sledeće', // Unicode for left arrow
          close: 'Close',
          last: 'Izađi',
        }}
      />
    </div>
  )
}
export default Booking;
