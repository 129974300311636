import React from 'react'
import {doc, setDoc,deleteDoc} from "firebase/firestore"; 
import {db} from "../../config/firebase-config";
import {auth,analytics} from "../../config/firebase-config";
import { useStateValue } from '../StateProvider';
import Header from '../Header/Header';
import { Outlet, useLocation } from 'react-router-dom';
import './ProfilePage.css'
import ProfileCard from '../ProfileCard/ProfileCard';
import MiniHeader from '../MiniHeader/MiniHeader';
import Book from '../Book/Book';
import { useParams } from 'react-router-dom';
import { query, where} from 'firebase/firestore';
import { collection, getDocs,Timestamp,updateDoc,arrayUnion } from 'firebase/firestore';
import { useState, useEffect } from 'react'; 
import 'react-chat-elements/dist/main.css'
import myImage from './Custom-Icon-Design-Flatastic-11-Text-message.512.png';
import useWindowWidth from '../WindowsWidthHook';
import SpeechBubble from "../SpeechBubble/SpeechBubble";
import { ReactComponent as Avatar } from '../../SVGs/Avatar.svg';
import Joyride, { CallBackProps, STATUS, ACTIONS } from 'react-joyride';
import { useNavigate } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import SimpleDialog from '../PopUps/SimpleDialog';
import { set } from 'date-fns';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import VideoPopUp from '../PopUps/VideoPopUp/VideoPopUp';
import Footer from '../Footer/Footer';

const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator.standalone);
let myNotification = !('Notification' in window) ? {} : Notification;


<a href="https://www.freepik.com/icons/message">Icon by Freepik</a>

function changeLastCharacter(str) {
  if (str.length === 0) return str; 

  const lastChar = str[str.length - 1];
  const vowelsLatin = 'aeiou';
  const vowelsCyrillic = 'аеиоуыэюя';
  let newChar;
  let isVowel = false;

  // Check for Latin 'a'
  if (lastChar === 'a') {
      newChar = 'i';
      isVowel = true;
  }
  // Check for Cyrillic 'а'
  else if (lastChar === 'а') {
      newChar = 'и';
      isVowel = true;
  }
  // Determine if the last character is a vowel (for both alphabets)
  else {
      if (vowelsLatin.includes(lastChar.toLowerCase()) || vowelsCyrillic.includes(lastChar.toLowerCase())) {
          isVowel = true;
      }

      if (lastChar >= '\u0400' && lastChar <= '\u04FF') { // Cyrillic range
          newChar = 'у'; // Cyrillic 'u'
      } else {
          newChar = 'u'; // Latin 'u'
      }
  }

  // Apply the new rule: if the last character is a vowel, replace it; otherwise, add the new character
  if (isVowel) {
      return str.substring(0, str.length - 1) + newChar;
  }else if(str==="Aleksandar") {
    return 'Aleksandru'
  } 
  else {
      return str + newChar;
  }
}


function normalizeName(name) {
  return name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}




function ProfilePage() {
  
  const navigateP = useNavigate();
  const { name } = useParams();
  const [image2, setImage2] = useState();
  const [description2, setDescription2]= useState();
  const [spec1, setSpec1] = useState();
  const [spec2, setSpec2] = useState();
  const [spec3, setSpec3] = useState();
  const width = useWindowWidth();
  const [wideProfilePage, setWideProfilePage] = useState(width>1470)
  const [run, setRun] = useState(false);
  const [appo,setAppo] = useState(false);
  const [loading,setLoading] = useState(true);
  const [processEnded,setProcessEnded] = useState(false);
  let [averageRating,setAverageRating] = useState(null);
  const [{basket, justBooked}, dispatch] = useStateValue();
  const nameDecode = decodeURIComponent(name);
  const noDot = nameDecode.replace("."," "); 
  const [profileUserType, setProfileUserType] = useState();
  const [isOpenSimpleDialog, setIsOpenSimpleDialog] = useState(false);
  const [isPopUpVideoOpen, setIsPopUpVideoOpen] = useState(false);
  const [run3,setRun3] = useState(false);
    const videoUrl = 'https://firebasestorage.googleapis.com/v0/b/evaapp-46d63.appspot.com/o/Instrukcije%20za%20ajfon.mp4?alt=media&token=e06bb3f1-019d-4895-8a7d-ba24dd45813e';
    const openVideoPopup = () => setIsPopUpVideoOpen(true);
  const closeVideoPopup = () => setIsPopUpVideoOpen(false);
  const navigate = useNavigate();
  

  useEffect(() => {
    if (auth.currentUser && !basket[0]?.privacyPoliceAccepted) {
      navigate('/terms-and-conditions')
      alert('Da biste koristili aplikaciju kao prijavljen korisnik potrebno je da prihvatite politiku privatnosti.')

    } else if (auth.currentUser && !basket[0]?.termsAndConditionAccepted) {
      navigate('/privacy-policy')
      alert('Da biste koristili aplikaciju kao prijavljen korisnik potrebno je da prihvatite uslove korišćenja.')
    }
  }, [])
  
  
  useEffect(()=> {
    if(justBooked && !basket[0]?.afterBookingAvatarSeen)  {
      setRun(true);
      try {
        const userRef = doc(db, "Clients", auth.currentUser.uid);
        const updateAvatar = async()=>{
          await updateDoc(userRef, {
            afterBookingAvatarSeen: true
        }
        );}
        updateAvatar();
      }
        catch(e){
          console.log(e)
        }
    }
    
  },[justBooked])
  

    const handleCloseSimpleDialog = ()=>{
      logEvent(analytics,'close_profile_simple_dialog', {
      })
      setIsOpenSimpleDialog(false);
    }

 


  const basketName = basket[0]?.name + '.' + basket[0]?.surname;
  const decodedBasketName = basketName.toLowerCase().trim();
  const trimmedName = name.trim();
  const comparisonName = normalizeName(trimmedName);
  const comparisonSurname = normalizeName(decodedBasketName);  
  const myProfile = comparisonName === comparisonSurname;

  useEffect(()=>{
    if (!myProfile && !basket[0]?.otherProfileAvatarSeen && auth.currentUser ) {
      setRun(true);
      const userRef = doc(db, "Clients", auth.currentUser.uid);
      const updateOtherProfileAvatar = async()=>{
        await updateDoc(userRef, {
      otherProfileAvatarSeen: true
      }
      );}
      updateOtherProfileAvatar();
    } else if(myProfile && !basket[0]?.myProfileAvatarSeen) {
      setRun(true);
      const userRef = doc(db, "Clients", auth.currentUser.uid);
      const updateMyProfileAvatar = async()=>{
        await updateDoc(userRef, {
      myProfileAvatarSeen: true
      }
      );}
      updateMyProfileAvatar();
    }
  },[])
  

  const handleJoyrideCallback = (data) => {
    const { action, index, type, status } = data;

    if (index === 0 && type === 'step:before') {
      // Log 'tutorial_begin' event
      logEvent(analytics, 'tutorial_begin', {
        tutorial_location:'ProfilePage'
      });
    }
  
    // Tutorial Complete
    if (status === 'finished') {
      // Log 'tutorial_complete' event
      logEvent(analytics, 'tutorial_complete', {
        tutorial_location:'ProfilePage'
      });
    }
  
    // Tutorial Closed
    if (status === 'skipped' || (status === 'closed' && index < steps.length - 1)) {
      // Log 'tutorial_closed' event, indicating the tutorial was closed before completion
      logEvent(analytics, 'feed_tutorial_closed', {
        step_index: index + 1, // Indicates the last step viewed before closing
        reason: status, // 'skipped' or 'closed'
        tutorial_location:'ProfilePage'
      });
    } 

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || action === ACTIONS.CLOSE) {
      setRun(false); // Stops the tour
    }
    // You can add more conditions here based on your requirements.
  };

  const handleJoyrideCallback2 = (data) => {
    const { action, index, type, status } = data;

    if (index === 0 && type === 'step:before') {
      // Log 'tutorial_begin' event
      logEvent(analytics, 'tutorial_begin', {
        tutorial_location: auth.currentUser ? 'Feed2' : 'Feed1'
      });
    }
  
    // Tutorial Complete
    if (status === 'finished') {
      // Log 'tutorial_complete' event
      logEvent(analytics, 'tutorial_complete', {
        tutorial_location: auth.currentUser ? 'Feed2' : 'Feed1'
      });
      
    }
  
    // Tutorial Closed
    if (status === 'skipped' || (status === 'closed' && index < steps.length - 1)) {
      // Log 'tutorial_closed' event, indicating the tutorial was closed before completion
      logEvent(analytics, 'feed_tutorial_closed', {
        step_index: index + 1, // Indicates the last step viewed before closing
        reason: status, // 'skipped' or 'closed'
        tutorial_location: auth.currentUser ? 'Feed2' : 'Feed1'
      });
     
    } 

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || action === ACTIONS.CLOSE) {
      setRun3(false); // Stops the tour
      setRun(true);
    }
    // You can add more conditions here based on your requirements.
  };
  
  const handleAvatarNotificationButtonClick = () => {
  
    
    if(isIOS && !isInStandaloneMode)
    {
      logEvent(analytics, 'open_video_avatar', {
        tutorial_location: 'profile_page',
        mode: isInStandaloneMode ? 'standalone' : 'browser',
      });
        setRun3(true);
        setRun(false);
    }else{

      logEvent(analytics, 'allow_notification', {
        tutorial_location: 'profile_page',
        mode: isInStandaloneMode ? 'standalone' : 'browser',
      });
      Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
        // With permission, now get messaging token
        const messaging = getMessaging();
        getToken(messaging, { vapidKey: 'BIUi3uPzzl-s6RnTjQR-KlYUiNHP0o3JfoFZvqZlKxGq_gK2dcjo6SOtHH8AsqZluiFEN6oopH6so08olnvIIEQ' })
          .then((currentToken) => {
            if (currentToken) {
              // Save or update the token in your database
              const clientDocRef = doc(db, 'Clients', auth.currentUser?.uid);
              setDoc(clientDocRef, { token: arrayUnion(currentToken) }, { merge: true });
            } else {
              console.log('No registration token available. Make sure notifications permissions are granted.');
            }
          }).catch((error) => {
            console.error('Error getting registration token:', error);
          });
      } else {
        console.log('Notification permission not granted.');
      }
    });}
  }
  
  


  const [steps, setSteps] = useState([
    {
      target:  justBooked && !basket[0]?.afterBookingAvatarSeen ? '.appoNav' : '.MiniProfileContainer',
      content: 
      <div className="AvatarContainer">
        <Avatar/>
        <SpeechBubble>
    {!myProfile ? 
    (<p>
    Na profilu se nalaze informacije koje je {capitalizeFirstLetterOfEachWord(name.split(".")[0])} ostavio/la o sebi.
      <br/>
      <br/>
    Osim informacija možeš videti i ocene koje su {changeLastCharacter(capitalizeFirstLetterOfEachWord(name.split(".")[0]))} dali drugi korisnici, a možeš mu/joj i poslati poruku.
      <br/>
    </p>)
    : justBooked && !basket[0]?.afterBookingAvatarSeen 
    ?
    ( <><p>
      Ovde se nalaze tvoji zakazani termini, klikom na termin možeš videti informacije o terminu.
      </p>
      
      { myNotification.permission !== "granted" ?<p>
        Ukoliko želiš da dobiješ obaveštenje kada ti izabrani ponuđač pošalje poruku ili kreira link, klikni na <ArrowDownwardIcon className='ArrowwDownwardIcon' style={{fontSize:"1.2rem", position:"relative", top:'0.2rem'}}/>  <br/> 
        <button style={{display: "flex",
    color: "white",
    backgroundColor: "hsla(174, 40%, 44%, 1)",
    padding: "3% 4%",
    borderRadius: "2vh",
    transition: "all .3s ease",
    cursor:" pointer",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1rem",
    borderWidth: "0.05vh",
    fontWeight: "500",
    width:" 100%",
    marginTop: "2vh",}}
    onClick={handleAvatarNotificationButtonClick}
    >Dozvoli obaveštenja</button>.
      </p> : null}
      </>
      )
    :
    basket[0].userType === 'client' ?
    (<p>
      Na svom profilu možeš ostaviti različite informacije o sebi, pregledati svoje trenutno zakazane termine i osnovne informacije o svakom terminu, kao i pristupiti istoriji dopisivanja sa ponuđačima.
        <br/>
        <br/>
      Infromacije koje si ostavio/la o sebi može videti samo ponuđač kod kog imaš zakazan termin.
       <br/>
      </p>)
      :
      (<p>
        Na svom profilu možeš ostaviti osnovne informacije o sebi kojima će pristup imati svako ukoliko okačiš svoj profil na feed.
          <br/>
          <br/>
        Pre nego što postaviš svoj profil na feed, potrebno je da podesiš svoje slobodne termine.
          <br/>
        </p>)
    

    }
  
</SpeechBubble>
      </div>,
      disableBeacon:true,
      placement: "center",
      styles:{
            options: {
              arrowColor: '#fff',
              backgroundColor: "hsl(165, 12%, 69%)",
              beaconSize: 36,
              overlayColor: 'rgba(0, 0, 0, 0.5)',
              primaryColor: 'rgba(123, 199, 191, 0.9)',
              spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
              textColor: '#333',
              width: "min(30rem, 90vw,90vh)",
              zIndex: 100,
            },
            
            
          }
    },{
      target: myProfile && (basket[0].userType === 'client') ? 'no class' : wideProfilePage ? ".arrow-button" : ".CalendarSymbol",
      content: 
      <div className="AvatarContainer" style={{position:"relative", top:"3rem"}}>
        <Avatar/>
        <SpeechBubble style={{position:"relative", bottom:"5rem", left:"-1rem"}}>
          <p>
            Klikom ovde ulaziš na stranicu za {myProfile ? "podešavanje svojih" : "zakazivanje"} termina.
          </p>
        </SpeechBubble>
      </div>,
      
      styles:{
            options: {
              arrowColor: '#fff',
              backgroundColor: "hsl(165, 12%, 69%)",
              beaconSize: 36,
              overlayColor: 'rgba(0, 0, 0, 0.5)',
              primaryColor: 'rgba(123, 199, 191, 0.9)',
              spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
              textColor: '#333',
              width: "min(30rem, 90vw,90vh)",
              zIndex: 100,
              offset: 100
            },
           
            buttonBack: {
              color: 'black', // This will apply the color black to the text of the back button
            },
            
          }
    },
  ]);


  const [steps3, setSteps3] = useState([
    
    {
      target: '.ProfilePage',
      content:
      <> 
      <div className="AvatarContainer">
        <Avatar/>
        <SpeechBubble >
    <p>
      S obzirom da koristiš iPhone, potrebno je da aplikaciju skineš na telefon kako bi mogao/la da primaš notifikacije.
      <br/>
      U kratom video tutorijalu ispod možeš videti kako da to uradiš u 3 klika.
    </p>
  
</SpeechBubble>


      </div>
      <img onClick={openVideoPopup} className="addToHomeInstructionVideo"
      src='/play-button-6694069_1280.png'
      >
        
      </img>
     </>,
      disableBeacon:true,
      placement: "center",
      styles:{
            options: {
              arrowColor: '#fff',
              backgroundColor: "hsl(165, 12%, 69%)",
              beaconSize: 36,
              overlayColor: 'rgba(0, 0, 0, 0.5)',
              primaryColor: 'rgba(123, 199, 191, 0.9)',
              spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
              textColor: '#333',
              width: "min(30rem, 90vw,90vh)",
              zIndex: 100,
              position: "relative",
              
            },      
          }
    },
    ]);
  
  useEffect(()=>{
    setWideProfilePage(width>1470);
  },[width])
  
  
  
  
  function capitalizeFirstLetterOfEachWord(str) {
    return str.toLowerCase().split(' ').map(function(word) {
        return word.charAt(0).toUpperCase() + word.substring(1);
    }).join(' ');
  }
  const headerName = capitalizeFirstLetterOfEachWord(noDot);

  useEffect(()=>{
    dispatch({
      type: "ADD_TO_HEADER",
      headerName: headerName,
      
    });
    ;
  },[headerName])
  


useEffect(() => {
  
  if (!myProfile && headerName) {
    const parts = headerName.split(' ');
    const firstName = parts[0]; // The first word is the first name
    let surname = '';

    if (parts.length > 1) {
        // Join the remaining parts as the surname
        surname = parts.slice(1).join(' ');
    }

    const firstNameTrimmed = decodeURIComponent(firstName.trim().trimEnd());
const surnameTrimmed = decodeURIComponent(surname.trim().trimEnd());


const q = query(
    collection(db, 'Clients'),
    where('name', '==', firstNameTrimmed),
    where('surname', '==', surnameTrimmed)
);

      getDocs(q)
        .then(querySnapshot => {
          if (!querySnapshot.empty) {
            const doc = querySnapshot.docs[0];
            const data = doc.data();
            const averageRating=data.averageRating;
            setAverageRating(averageRating);
            const imageUrl = data.imageURL;
            setImage2(imageUrl);
            setProfileUserType(data.userType);
            const descript = data?.description;
            setDescription2(descript);
            const specialization1 = data?.spec1
            const specialization2 = data?.spec2
            const specialization3 = data?.spec3
            setSpec1(specialization1);
            setSpec2(specialization2);
            setSpec3(specialization3);
            setAppo(data.setAppo);
            const dataWithId = { id: doc.id,myProfile, ...data };
            dispatch({
              type: 'ADD_TO_SPEC',
              spec: dataWithId,
              
              
            });  setLoading(false);
                 setProcessEnded(true); 
                 dispatch({
                  type: 'ADD_TO_PROCESS',
                  process: processEnded,
                });
 
          } else {
            console.log('No matching document found.');
          }
        })
        .catch(error => {
          console.error("Error fetching document with name:", name, "surname:", surname, "Error:", error);
      });
    }
    
  }, [headerName]);

 /*  useEffect(()=>{
    if(myProfile){
      setRun2(true);
      setRun(false);
    } else {
      setRun(true);
      setRun2(false);
    }
  },[myProfile]) */

  const toBooking = (spec,navigate) => {
    if(myProfile) {
      logEvent(analytics,'goto_appointment_settings', {
      })
      navigate(basket[0].setAppo ?  '/appointmentsSetting2':'/appointmentsSetting');
    } else if (auth.currentUser){
      logEvent(analytics,'goto_booking', {
        receiver_name: headerName
      })
    navigate('booking');}
    else {
      logEvent(analytics,'goto_booking_unauth', {
        receiver_name: headerName
      })
      setIsOpenSimpleDialog(true);
    }
  }

 useEffect(() => {
  const updateAppointments = async () => {
      if (auth.currentUser?.uid) {
        
          const now = Timestamp.now();
          const oneHourAgo = new Timestamp(now.seconds - 3600, 0); // 3600 seconds = 1 hour

          const appointmentsRef = collection(db, 'Clients', auth.currentUser.uid, 'Appointments');
          const q = query(appointmentsRef, where('date', '<=', oneHourAgo),where('ended', '==', false));

          try {
              const querySnapshot = await getDocs(q);
              if (!querySnapshot.empty) {
                  
                  querySnapshot.forEach(async (docSnapshot) => {
                      const docRef = doc(db, 'Clients', auth.currentUser.uid, 'Appointments', docSnapshot.id);
                      await updateDoc(docRef, { ended: true });
                  });
              } else {
              }
          } catch (error) {
              console.error("Error updating appointments: ", error);
          }
      }
  };

  updateAppointments();
}, []);
  return (
    <div className='ProfilePage'>
      <Header name={basket[0].name} surname={basket[0].surname} myProfile={myProfile} basketName={basketName}></Header>
      
      <div className = "ProfileContainer">
      <div className= 'MiniProfileContainer'>
        <ProfileCard appo={appo} processEnded={processEnded} toBooking={toBooking} profileUserType={profileUserType} averageRating={averageRating} myImage = {myImage} spec1={spec1} spec2={spec2} spec3={spec3} description2={description2} headerName={headerName} image2={image2} myProfile={myProfile} setIsOpenSimpleDialog={setIsOpenSimpleDialog}></ProfileCard>
        { myProfile && basket[0].userType === 'client' || profileUserType ==='client' ?
        null
        : 
        <Book toBooking={toBooking} myProfile = {myProfile} auth = {auth}></Book>
        }
        
      <MiniHeader  profileUserType={profileUserType} name={name} myProfile={myProfile}></MiniHeader>
      <Outlet></Outlet>
      </div>
      </div>
      <Joyride
  continuous
  run={run} // Ensure this is set to true to start automatically
  steps={steps}
  callback={handleJoyrideCallback}
  disableOverlayClose={true}
  locale={{
    back: 'Nazad', // Unicode for right arrow
    next: 'Sledeće', // Unicode for left arrow
    close: 'Close',
    last: 'Izađi',
  }}
/>

<SimpleDialog isOpenSimpleDialog={isOpenSimpleDialog} handleCloseSimpleDialog={handleCloseSimpleDialog}>
  <p style = {{fontSize: '0.9rem', color: 'hsla(0, 0%, 20%, 1)', fontWeight:400, }}>Ukoliko želiš da zakažeš termin ili pošalješ poruku, potrebno je da se prvo <b>registruješ.</b></p>
  <br></br>
  <p className="hoverable" onClick={()=>{logEvent(analytics,'go_to_login', {}); navigateP('/login')}} style={{
    marginTop: "0.5rem",
    fontSize: "0.9rem",
    color: "hsla(174, 40%, 63%, 1)",
    borderBottom: "0.1rem solid hsla(0, 0%, 85%, 1)",
    paddingBottom: "0.3rem",
    cursor: "pointer",
    display: 'flex',
    alignItems: 'center', // Aligns the SVG icon with the text
}}>
    <svg 
        viewBox="0 0 24 24" 
        width="24" 
        height="24" 
        style={{ marginRight: '0.5rem' }}>
        <path fill="currentColor" d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
    </svg>
    Registruj se
</p>
</SimpleDialog>

<Joyride
  continuous
  run={run3} // Ensure this is set to true to start automatically
  steps={steps3}
  callback={handleJoyrideCallback2}
  disableOverlayClose={true}
  locale={{
    back: 'Nazad', // Unicode for right arrow
    next: 'Sledeće', // Unicode for left arrow
    close: 'Close',
    last: 'Izađi',
  }}
/>

<VideoPopUp
isPopUpVideoOpen={isPopUpVideoOpen}
closeVideoPopup={closeVideoPopup}
videoUrl={videoUrl}
      />
      <Footer/>
  </div>
    
  )
}
export default ProfilePage