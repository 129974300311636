import React, { useState } from 'react';
import './AboutDialog.css';
import TextareaAutosize from '@mui/material/TextareaAutosize' 
import { doc, setDoc } from "firebase/firestore";
import {db,auth} from "../../config/firebase-config"

function AboutDialog({ isOpen, onClose }) {
    const [description, setDescription] = useState('');


    

    const handleSubmit = () => {
        const clientRef = doc(db, 'Clients', auth.currentUser.uid);
        setDoc(clientRef, { aboutMe: description }, { merge: true });
        
        onClose();
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className="dialogOverlay">
            <div className="dialogContainer">
                <div className="dialogHeader">
                    Enter Description
                    <span className="closeDialog" onClick={onClose}>x</span>
                </div>
                <div className="dialogMain">
                    <TextareaAutosize 
                        aria-label="minimum height" 
                        minRows={6} 
                        placeholder="Enter a detailed description..." 
                        value={description} 
                        onChange={e => setDescription(e.target.value)} 
                        maxLength={750}
                        
                    />
                </div>
                <div className="dialogFooter">
                    <button className='submitButton' onClick={handleSubmit}>Submit</button>
                </div>
            </div>
        </div>
    );
}

export default AboutDialog;
