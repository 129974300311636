import { useEffect, useState } from 'react';
import './HourDialog.css';
import { db } from '../../config/firebase-config';
import { arrayUnion, doc, getDoc, } from "firebase/firestore";
import { auth } from '../../config/firebase-config';
import { updateDoc, setDoc } from "firebase/firestore";
import { useStateValue } from '../StateProvider';

function HourDialog({ isOpen, onClose, serbianDay, times, setTimes, currentDate }) {
    const [selectedTime, setSelectedTime] = useState('00:00');
    const [buttonCounter, setButtonCounter] = useState(0);
    const [{ basket }, dispatch] = useStateValue();
    
    

    let newTimes = [];

    const handleSubmit = async () => {

        let list = null;

        switch (basket[0].userType) {
            case 'razgovor':
                list = "ListOfIds1"
                break;
            case 'savetnik':
                list = "ListOfIds2"
                break;
            case 'psychotherapy':
                list = "ListOfIds3"
                break;
        }

        try {
            const docRef = doc(db, "Clients", auth.currentUser.uid, "WorkingTime", serbianDay);
            const docRef2 = doc(db, "Clients", auth.currentUser.uid, "SpecificDates", currentDate);
            const docRef3 = doc(db, "Clients",auth.currentUser.uid);
            const docRef4 = doc(db, "ClientsIds", list);
            


            const docSnap = await getDoc(docRef);
            const docSnap2 = await getDoc(docRef2);
            
            if (docSnap2.exists()) {
                const data = docSnap2.data();
                const valueToAdd = data.hours;
                valueToAdd.push(selectedTime);
                newTimes = [...valueToAdd];
                setTimes([...newTimes]);


                await setDoc(docRef2, {
                    hours: newTimes
                });
                
                
            } else if (docSnap.exists()) {
                const data = docSnap.data();
                const valueToAdd = data.hours;
                valueToAdd.push(selectedTime);
                newTimes = [...valueToAdd];
                setTimes([...newTimes]);
                await setDoc(docRef2, {
                    hours: newTimes
                });

                } else {
                    setTimes([selectedTime]);

                await setDoc(docRef2, {
                    hours: [selectedTime]
                });

                /* await updateDoc(docRef3, {
                    "workingDays.specificDates": arrayUnion(currentDate)
                }); */

                const specificDates = basket[0].workingDays.specificDates
                specificDates.push(currentDate);
                console.log('specificDates', specificDates);
                await updateDoc(docRef4, {
                    [`ids.${auth.currentUser.uid}.workingDays.specificDates`]:specificDates
                    
                })
                .then(() => {
                    console.log("Document successfully updated!");
                })
        .catch((error) => {
            console.error("Error updating document: ", error);
        });
                }
                setButtonCounter(prev => prev+1);
        } catch (error) {
            console.error("Error fetching or updating value from Firestore:", error);
        }

        onClose();
    };

    useEffect(()=>{
        dispatch({
            type: 'ADD_TO_PROCESS',
            process: buttonCounter,
          });
    },[buttonCounter])

    if (!isOpen) {
        return null;
    }

    return (
        <div className="dialogOverlay">
            <div className="dialogContainer">
                <div className="dialogHeader">
                    Odaberi slobodan termin
                    <span className="closeDialog" onClick={onClose}>x</span>
                </div>
                <div className="dialogMain">
                    <select
                        value={selectedTime}
                        onChange={(e) => setSelectedTime(e.target.value)}
                    >
                        {[...Array(24).keys()].map((hour) => (
                            <option key={hour} value={`${hour < 10 ? '0' : ''}${hour}:00`}>
                                {hour < 10 ? '0' : ''}{hour}:00
                            </option>
                        ))}
                    </select>
                </div>
                <div className="dialogFooter">
                    <button onClick={handleSubmit}>Submit</button>
                </div>
            </div>
        </div>
    );
}

export default HourDialog;
