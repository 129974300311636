import './SpecDiv.css'
import Switch from '@mui/material/Switch';
import { useEffect, useState } from 'react';
import { getDoc,doc, updateDoc,query,collection,where,getDocs } from 'firebase/firestore';
import { auth,db } from '../../config/firebase-config';
import RingLoader from "react-spinners/RingLoader";
import { useNavigate } from 'react-router-dom';

function SpecDiv(props) {
    const [symbolClicked, setSymbolClicked] = useState(0);
    const [Hours, setHours] = useState([]);
    const navigate = useNavigate();
    const isAppointed = props.appoHours.includes(props.hour);
    const [pictureURL, setPictureURL] = useState();
    const [loading, setLoading] = useState(true);
    const [activeToogle, setActiveToogle] = useState(0);
    const [color, setColor] = useState('');
    let [targetDocRef ,setTargetDocRef] = useState();
    const [removed , setRemoved] = useState(0);
    let [targetDocSnap, setTargetDocSnap] = useState(null);
    let nameHour = props.appoNames?.find(element => element.appoTime === props.hour) || null;
    let [nameNUse, setNameNUse] = useState('');
    let [nameSUse, setNameSUse] = useState('');
    let [price, setPrice] =useState('');
    let [useImageURL, setUseImageURL] = useState();
    
    let [account,setAccount] =useState('')
    
    let nameN ='';
    let nameS ='';
    
    if (isAppointed) 
    { [nameN, nameS] = nameHour.appoName.split('_')[0].split(' ');
                
}


        let dotName = '';
    if (nameHour) {
        dotName = nameHour.appoName.split('_')[0].toLowerCase().replace(' ', '.');
    }
 
async function fetchImageURL() {
 if(isAppointed)
  {const clientsRef = collection(db, "Clients");
  const q = query(
    clientsRef,
    where("name", "==", nameN),    // Assuming nameN is a function you've defined
    where("surname", "==", nameS) // Assuming nameS is a function you've defined
    
  );

  const querySnapshot = await getDocs(q);
  let imageURL = "";
  setUseImageURL(imageURL);
  
  
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    if (data.imageURL) {
      imageURL = data.imageURL;
    }
    if (data.address) {
      setAccount(data.address);
    }
  });

  return imageURL;
}}

useEffect(() => {
    if (isAppointed) {
        fetchImageURL().then(url => {
            setPictureURL(url);
            
        });
        
    }
}, [isAppointed, nameN, nameS]);



    function removeItem(array, itemToRemove) {
        return array.filter(item => item !== itemToRemove);
    }
    
    const fetchDocAndListen = async () => {
        try {
            const specificDateRef = doc(db, "Clients", auth.currentUser.uid, "SpecificDates", props.currentDate);
            const specificDateSnap = await getDoc(specificDateRef);
    
            // Check if the SpecificDates document exists
            if (specificDateSnap.exists()) {
                setTargetDocRef(specificDateRef); // Still setting the ref for other operations
                return specificDateSnap;  // Returning the snapshot for immediate processing
    
            } else {
                const defaultRef = doc(db, 'Clients', auth.currentUser.uid, 'WorkingTime', props.serbianDay);
                setTargetDocRef(defaultRef);
                const defaultSnap = await getDoc(defaultRef);  // Renaming for clarity
                return defaultSnap;  // Returning the snapshot for immediate processing
            }
        } catch (error) {
            console.error("Error fetching document:", error);
            return null;  // Return null in case of errors
        }
    }

    useEffect(() => {
    
        const fetchDataAndUpdate = async () => {
            try {
                const currentDocSnap = await fetchDocAndListen(); 
                if (currentDocSnap && currentDocSnap.exists()) {
                    let data = currentDocSnap.data();
                    setHours(data.hours);  
                    if(activeToogle === 0 ) {
                        setColor('hsla(174, 40%, 63%, 1)');
                        
                    } else if(activeToogle === 1) {
                        
                        setColor('hsla(0, 0%, 90%, 1)');
                    }
                    setLoading(false); 
                } else {
                    
                    setLoading(false); 
                }
            } catch (error) {
                console.error("Error in fetchDataAndUpdate:", error);
                setLoading(false); 
            }
        };
    
        fetchDataAndUpdate();

        
    }, [activeToogle, symbolClicked]);



  function onSymbolClick() {
    if (activeToogle === 1) {
        let newHours = removeItem(Hours, props.hour);
        updateDoc(targetDocRef, {
            hours: newHours
        }).catch(error => console.error("Error updating the document:", error));
    }
        props.setTimes((times) => times.filter(hour=>hour !== props.hour));
        setSymbolClicked((prev) => {prev = prev + 1});
    
   }

    function handleToogle(e) {
        setActiveToogle(activeToogle === 0 ? 1 : 0);
    }
    if (loading) {
        return <div style={{display:"flex",
                        justifyContent:'center',
                      alignItems:"center",
                    height:'100vh'}}><RingLoader
    color={'#4dffdb'}
    loading={loading}
    size={150}
    aria-label="Loading Spinner"
    data-testid="loader"
  /></div>;
      }

    return (
        
        <div className="SpecDiv" style = {!isAppointed ? {backgroundColor : color} : 
        {backgroundColor : 'white'}} /* onClick={isAppointed && dotName ? () =>{props.setIsDialogOpen2(true); props.setNameN(nameN); props.setNameS(nameS); props.setImageUrl(useImageURL); if(nameHour.appoLink)
            {props.setLocation(nameHour.appoLink);}else {props.setLocation('Uživo')}} : null} */>
            <div className="HourSpec">{props.hour}</div>
            <div className='SwitchContainer'>
                {!isAppointed ?<Switch className="SpecSwitch" defaultChecked size='small'
                color='default' onChange={handleToogle} />: <div className = 'PictureContainer'>
                    <img src={pictureURL} className='SpecDivPicture'></img>
                    </div>}
                <span className='SpanText'> {isAppointed ? nameHour.appoName.split('_')[0] 
                    : (activeToogle === 0 ? 'Doustupan' : 'Nedostupan')}
                </span>
            </div>
            <div onClick={activeToogle == 1 ? onSymbolClick:null}className='SpecSymbol'>{activeToogle === 0 ? 'o' : 'x'}</div>

        </div>
    )
}
export default SpecDiv;
