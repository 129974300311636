import React from 'react';
import './TermsAndCondition.css';
import Header from '../Header/Header';
import { useStateValue } from '../StateProvider';
import { useState } from 'react';
import { auth, db } from '../../config/firebase-config';
import { doc, setDoc} from "firebase/firestore";
import SimpleDialog from '../PopUps/SimpleDialog';


function PrivacyPolice() {
    const [{ basket }, dispatch] = useStateValue();
    const [termsAndConditionAccepted, setTermsAndConditionAccepted] = useState(basket[0]?.termsAndConditionAccepted);
    const [isOpenSimpleDialog, setIsOpenSimpleDialog] = useState(false);
    
    const handleTermsAndConditionCheckBoxClick = async () => {

        setTermsAndConditionAccepted(prev => !prev);

        dispatch({
            type: 'UPDATE_BASKET_PROPERTY',
            property: 'termsAndConditionAccepted', // The name of the property you want to update
            value: !termsAndConditionAccepted // The new value for the property
        });

        await setDoc(doc(db, 'Clients', auth.currentUser.uid), {
            termsAndConditionAccepted: !termsAndConditionAccepted
        }, { merge: true });
        const consentRef = doc(db, 'Clients', auth.currentUser.uid, 'consents', new Date().toISOString());
        await setDoc(consentRef, {
            termsAndConditionAccepted: !termsAndConditionAccepted
        }, { merge: true });
    }

    const handleCloseSimpleDialog = () => {
        setIsOpenSimpleDialog(false);
    }

    return (
        <div className='PrivacyPoliceContainer'>
            <Header name={basket[0]?.name} surname={basket[0]?.surname} />
            <main className='PrivacyPolice'>
                {auth.currentUser ?
                    <div className='privacyPoliceDiv'>
                      <label className='privacyPolicyLab el'>
                      Prihvatam uslove korišćenja &nbsp;
                        <input
                        name="newsletter"
                        type="checkbox"
                        className='privacyPolicyCheckBox'
                        onChange={handleTermsAndConditionCheckBoxClick}
                        value={termsAndConditionAccepted}  
                        checked = {termsAndConditionAccepted}                 
                    />
                        </label>
                    </div>
                    :
                    null}
                <h1 id="politika-privatnosti">Opšti uslovi korišćenja i poslovanja</h1>
                <br/>
                <h2 id="1-uvod">1. Uvod</h2><br />
                <p>Dobrodošli u Eva aplikaciju. Ovaj dokument (<em>Opšti uslovi korišćenja i poslovanja</em>) uređuje prava i obaveze klijenata i pružalaca usluga na našoj platformi. Svrha ovog dokumenta je da Vas informiše o pravilima korišćenja naše aplikacije, kao i o uslovima poslovanja vezanim za pružanje i korišćenje usluga psihološke podrške putem Eva aplikacije.</p>
                <br /><p><strong>Definicije ključnih termina:</strong> </p>
                <ol><br />
                    <li><strong>nalog</strong> označava jedinstveni nalog kreiran za Vas kako biste pristupili našoj usluzi ili delovima naše usluge;</li>
                    <br /><li><strong>aplikacija</strong> se odnosi na Evu, softverski program koji pruža kompanija;</li>
                    <br /><li><strong>kompanija</strong> (označena kao ,,kompanija&quot;, ,,mi&quot;, ,,nas&quot;, ,,naš&quot; ili ,,platforma&#39;&#39; u ovom ugovoru) odnosi se na Evu;</li>
                    <br /><li><strong>država</strong> se odnosi na Srbiju;</li>
                    <br /><li><strong>uređaj</strong> znači bilo koji uređaj koji može pristupiti usluzi kao što su računar, mobilni telefon ili digitalni tablet;</li>
                    <br /><li><strong>lični podaci</strong> su bilo koje informacije koje se odnose na identifikovanu osobu ili osobu koja se može identifikovati;</li>
                    <br /><li><strong>usluga se odnosi na aplikaciju, veb-sajt, sve funkcionalnosti i sadržaje dostupni putem Eva aplikacije uključujući povezivanje sa pružaocima usluga, zakazivanje sesija, i vođenje sesija putem audio-vizuelnog poziva ili audio-vizuelnog poziva sa isključenom kamerom;</strong></li>
                    <br /><li><strong>korisnik</strong> je svaka osoba koja ima minimum 16 godina i koja koristi Eva aplikaciju bilo kao klijent koji traži psihološku podršku ili kao pružalac psihološke podrške;</li>
                    <br /><li><strong>pružalac usluge (ili samo pružalac) je osoba koji nudi psihološku podršku</strong> putem Eva aplikacije i nije zaposlena kod nas niti nas na bilo koji način predstavlja, već joj mi omogućavamo oglašavanje na Eva aplikaciji; pružaoci mogu koristiti Eva aplikaciju i u ulozi klijenta (u tom slučaju stiče sva prava koja imaju klijenti osim mogućnosti korišćenja pseudonima), dok obranuto ne važi: klijent je ne može koristiti u ulozi pružaoca psihološke podrške;</li>
                    <br /><li><strong>klijent</strong> je osoba koja potražuje uslugu psihološke podrške na Eva aplikaciji;</li>
                    <br /><li><strong>sesija</strong> se odnosi na individualne audio-vizuelne ili audio-vizuelne pozive sa isključenom kamerom putem Google Meet linka između pružaoca psihološke podrške i klijenta, ona jasno i nedvosmisleno nije zdravstvena usluga.</li>
                </ol>
                <br /><p><strong>Ko smo mi?</strong></p>
                <br /><p><strong>Eva je internet platforma</strong> koja povezuje pružaoce psihološke podrške sa njihovim klijentima. <strong>Eva ne pruža zdravstvene usluge niti bilo ko od pružalaca psihološke podrške pruža zdravstvene usluge.</strong> Dalje, <strong>Eva platforma nije namenjena za postavljanje kliničke dijagnoze, pružanje informacija u vezi sa lekovima i izdavanje recepata i ukoliko Vam neko od pružalaca nudi i/ili pruži ove usluge, ignorišite ih i obavestite nas o tome.</strong></p>
                <br /><p><strong>Eva nije ugovorna strana</strong> u odnosu između pružaoca psihološke podrške i klijenta, već je isključivo posrednik.</p>
                <br /><p>Kako bismo maksimalno osigurali bezbednost ljudi koji koriste Eva aplikaciju, napominjemo: <strong>u slučaju kriznih situacija nemojte dolaziti na Eva platformu, već se obratite Nacionalnoj SOS liniji za prevenciju samoubistva na broj telefona 011/7777-000, a u slučaju da vam je potrebna hitna medicinska pomoć, pozovite broj 194.</strong></p>
                <br /><h2 id="2-op-ti-uslovi-kori-enja">2. Opšti uslovi korišćenja</h2>
                <br /><h3 id="2-1-pravila-pona-anja">2.1 Pravila ponašanja</h3>
                <br /><ul>
                    <li>Korisnici ne smeju zloupotrebljavati aplikaciju uključujući pokušaje hakovanja i ometanja rada aplikacije.</li>
                    <br /><li>Zabranjeno je objavljivati i deliti nezakonit, uvredljiv i neprimeren sadržaj uključujući govor mržnje, direktne i indirektne pretnje, diskriminaciju po rasnoj, nacionalnoj, političkoj, polnoj, rodnoj, seksualnoj osnovi ili po drugoj osnovi.</li>
                    <br /><li>Zabranjeno je predstavljati svoju praksu kao naučnu ukoliko to nije.</li>
                    <br /> <li>Zabranjeno je izbegavanje zakazivanja termina preko Eva platforme ukoliko su pružalac psihološke podrške i klijent naši korisnici.</li>
                    <br /><li>Zabranjeno je da korisnici na svom profilu ili u porukama ostavljaju bilo koji kontakt putem kog bi mogli da pristupe jedno drugom.</li>
                    <br /><li>Korisnici moraju poštovati prava drugih korisnika.</li>
                    <br /><li>Korisnici ne smeju koristiti aplikaciju za komercijalne svrhe bez prethodnog našeg odobrenja.</li>
                    <br /><li>Korisnici moraju koristiti aplikaciju u skladu sa svim važećim zakonima i propisima.</li>
                </ul>
                <br /><h3 id="2-2-prava-i-obaveze-pru-aoca-psiholo-ke-podr-ke">2.2 Prava i obaveze pružaoca psihološke podrške</h3>
                <br /><p>Pružaoci psihološke podrške imaju sledeća <strong>prava:</strong></p><br />
                <br /><p><strong>registracija:</strong> pružalac psihološke podrške ima pravo da se registruje na Eva aplikaciji kako bi mogao nuditi svoje usluge;<br />
                    <br /><strong>kreiranje profila:</strong> pružalac psihološke podrške ima pravo da napravi svoj profil na Eva aplikaciji;<br />
                    <br /><strong>određivanje radnog vremena:</strong> pružalac psihološke podrške ima pravo da sam odredi svoje uobičajeno radno vreme i/ili specifične termine u kojima nudi uslugu;<br />
                    <br /><strong>određivanje naknade:</strong> pružalac psihološke podrške ima pravo da odluči da li će uslugu nuditi besplatno ili po novčanoj naknadi, i da sam odredi iznos te naknade;<br />
                    <br /><strong>određivanje načina uplate:</strong> pružalac psihološke podrške ima pravo da sam odredi na koji način želi da mu se izvrši uplata;<br />
                    <br /><strong>komentarisanje:</strong> pružaoci imaju pravo da odgovore na komentar klijenta o svom profesionalno radu uz poštovanje načela poverljivosti i privatnosti;<br />
                    <br /><strong>dodatne informacije i pomoć:</strong> pružalac psihološke podrške ima pravo da nam se obrati za sve dodatne informacije, pitanja ili pomoć putem kontakt informacija navedenih u aplikaciji;<br />
                    <br /><strong>pristup i upravljanje podacima:</strong> pružalac psihološke podrške ima pravo da traži pristup, ažurira, izbriše ili zatraži pomoć u brisanju ličnih podataka koje smo prikupili o njemu;<br />
                    <br /><strong>prenos podataka:</strong> pružalac psihološke podrške ima pravo da zatraži sve svoje lične podatke od kontrolora i prenese ih drugom kontroloru.</p><br />
                <br /><p><em>Napomena: preporučujemo dodavanje načina plaćanja za klijente koji nisu iz Vaše države.</em></p>
                <br /><p>Pružaoci psihološke podrške imaju sledeće <strong>obaveze:</strong></p>
                <br /><p><strong>profesionalno ponašanje:</strong> pružalac je dužan da pruži usluge na profesionalan način i u skladu sa etičkim standardima svoje profesije;<br />
                    <br /> <strong>načelo privatnosti i poverljivosti:</strong> pružalac je dužan da identitet i sadržinu sesija sa klijentima čuva u tajnosti;<br />
                    <br /><strong>tačnost informacija:</strong> pružalac je dužan da obezbedi tačnost informacija na svom profilu i oglasima za usluge;<br />
                    <br /><strong>dokumentacija:</strong> pružalac je dužan da nam putem naše e-mail adrese: evawellbeing@gmail.com dostavi svoju biografiju i/ili CV, kao i slike diplome/a i sertifikata za koje na svom profilu na Eva aplikaciji tvrdi da ima;<br />
                    <br /><strong>tehnička priprema:</strong> pružalac je dužan da obezbedi tehničke uslove za održavanje sesija putem Google Meet linka;<br />
                    <br /><strong>poštovanje zakona:</strong> pružalac je dužan da poštuje sve važeće zakone i regulative vezane za pružanje psihološke podrške.</p><br />
                <br /><h3 id="2-3-prava-i-obaveze-klijenta">2.3 Prava i obaveze klijenta</h3>
                <br /><p>Korisnici koji traže psihološku podršku putem Eva aplikacije imaju sledeća <strong>prava:</strong></p>
                <ul>
                    <br /><li><strong>registracija:</strong> klijent ima pravo da se registruje na Eva app kako bi mogao koristiti usluge platforme;</li>
                    <br /><li><strong>kreiranje profila:</strong> klijent ima pravo da napravi svoj profil na Eva aplikaciji;</li>
                    <br /><li><strong>anonimnost:</strong> klijent može koristiti pseudonim kao korisničko ime ukoliko želi da ostane anoniman, kao i da isključi kameru tokom Google meet video poziva</li>
                    <br /><li><strong>zakazivanje sesija:</strong> klijent ima pravo da zakazuje sesije sa pružaocima psihološke podrške putem Eva aplikacije;</li>
                    <br /><li><strong>ocenjivanje i komentarisanje:</strong> klijent ima pravo da oceni pružaoca psihološke podrške čije je usluge koristio i ostavi komentar o njegovom profesionalnom radu;</li>
                    <br /><li><strong>dodatne informacije i pomoć:</strong> klijent ima pravo da nam se obrati za sve dodatne informacije, pitanja ili pomoć putem kontakt informacija navedenih u aplikaciji;</li>
                    <br /><li><strong>pristup i upravljanje podacima:</strong> klijent ima pravo da traži pristup, ažurira, izbriše ili zatraži pomoć u brisanju ličnih podataka koje smo prikupili o njemu;</li>
                    <br /><li><strong>prenos podataka:</strong> klijent ima pravo da zatraži sve svoje lične podatke od kontrolora i prenese ih drugom kontroloru.</li>
                </ul><br />
                <p>Korisnici koji traže psihološku podršku putem Eva aplikacije imaju sledeća <strong>obaveze</strong>:</p>
                <ul>
                    <br /><li><strong>plaćanje:</strong> klijent je dužan da najkasnije 12 sati pre zakazanog termina izvrši uplatu za sesiju;</li>
                    <br /><li><strong>prisustvo:</strong> klijent je dužan da se pojavi na sesiji u dogovoreno vreme;</li>
                    <br /><li><strong>tehnička priprema:</strong> klijent je dužan da tehnički obezbedi mogućnost održavanja audio-vizuelnog ili audio poziva sa pružaocem psihološke podrške putem Google Meet linka;</li>
                    <br /><li><strong>poštovanje zakona:</strong> klijent je dužan da poštuje sve važeće zakone i regulative vezane za pružanje psihološke podrške.</li>
                </ul><br />
                <p>*Napomene:</p>
                <ol>
                    <br /><li><em>Ocene i komentari su javni što znači da će se prikazati korisničko ime klijenta.</em></li>
                    <br /><li><em>Ocene, komentari i odgovori na komentare ne predstavljaju stavove Eva platforme.</em></li>
                </ol>
                <br /><h3 id="2-4-privatnost-i-za-tita-podataka">2.4 Privatnost i zaštita podataka</h3>
                <br /><p><strong>Politika privatnosti:</strong></p>
                <ul>
                    <br /><li>Eva prikuplja i obrađuje lične podatke korisnika u skladu sa važećim zakonima i politikom privatnosti koja je dostupna na našem sajtu.</li>
                    <br /><li>Pročitajte našu <a href="https://evawellbeing.web.app/privacy-policy">Politiku privatnosti</a> .</li>
                </ul><br />
                <p><strong>Zaštita ličnih podataka</strong></p>
                <br /><ul>
                    <br /><li>Korisnici imaju pravo na pristup svojim ličnim podacima, ispravku netačnih podataka i brisanje podataka u skladu sa politikom privatnosti.</li>
                    <br /><li>Eva preduzima sve potrebne mere zaštite kako bi osigurala sigurnost podataka korisnika.</li>
                </ul><br />
                <br /><h3 id="2-5-procedura-re-avanja-sporova">2.5 Procedura rešavanja sporova</h3>
                <br /><p><strong>Način rešavanja sporova:</strong></p>
                <ul><br />
                    <br /><li>Kršenjem bilo kog pravila ponašanja iz tačke 2.1 ovog dokumenta rezultiraće gašenjem profila tog korisnika i onemogućavanjem da dalje koristi Eva aplikaciju.</li>
                    <br /> <li>U slučaju spora između korisnika i pružaoca usluge, korisnici su pozvani da prvo kontaktiraju Evu za podršku kako bi pokušali da reše spor na prijateljski način. </li>
                    <br /> <li>Ako spor ne može biti rešen na ovaj način, biće upućen na rešavanje nadležnom sudu u skladu sa zakonima Republike Srbije.</li>
                </ul><br />
                <br /><h3 id="2-6-intelektualna-svojina">2.6 Intelektualna svojina</h3>
                <ul><br />
                    <br /><li>Svi sadržaji i materijali dostupni na Eva aplikaciji i Eva sajtu uključujući softver, dizajn, tekstove, grafike i logo, zaštićeni su autorskim pravom i drugim pravima intelektualne svojine.</li>
                    <br /><li>Korisnici ne smeju reprodukovati, distribuirati ili na bilo koji način koristiti sadržaje aplikacije bez prethodnog odobrenja Eva platforme.</li>
                </ul><br />
                <br /><h2 id="3-op-ti-uslovi-poslovanja">3. Opšti uslovi poslovanja</h2>
                <br /><h3 id="3-1-prodaja-proizvoda-ili-usluga">3.1 Prodaja proizvoda ili usluga</h3>
                <br /><p><strong>Uslovi kupovine, plaćanja i isporuke</strong></p>
                <br /><ul>
                    <br /><li><strong>Kupovina:</strong> Korisnici mogu kupovati usluge psihološke podrške putem Eva aplikacije. </li>
                   <br /> <li><strong>Plaćanje:</strong> Plaćanje usluge pružaocu psihološke podrške vrši se van Eva platforme i nemamo pristup informacijama o klijentovoj platnoj kartici ukoliko je to način plaćanja. Pružalac psihološke podrške i klijent su odgovorni za sve pravne odnose i transakcije koje sklope putem aplikacije. Shodno tome, u slučaju reklamacije klijent se mora obratiti pružaocu psihološke podrške čije je usluge koristio.</li>
                    <br />  <li><strong>Isporuka:</strong> Usluge psihološke podrške se pružaju putem Google Meet online video sesija zakazanih unutar aplikacije. Korisnici će dobiti potvrdu o zakazanoj sesiji putem e-mail adrese i obaveštenje od strane Eva aplikacije. Kako bismo olakšali zakazivanje ovih sesija, naša aplikacija zahteva pristup Google kalendaru korisnika. Kako ovaj proces funkcioniše i šta podrazumeva pročitajte u našoj <a href="https://evawellbeing.web.app/privacy-policy">Politici privatnosti</a>. Ukoliko dođe do otkazivanja sesije od strane bilo kog korisnika u bilo kom trenutku, bez obzira na to da li je sesija plaćena ili ne, takav spor rešava se isključivo između pružaoca psihološke podrške i klijenta.</li>
                </ul><br />
                <br /><h3 id="3-2-pravna-regulativa">3.2 Pravna regulativa</h3>
                <br /><p><strong>Usklađenost sa zakonima i propisima koji regulišu poslovanje</strong></p>
                <ul><br />
                    <br /> <li>Eva platforma posluje u skladu sa zakonima Republike Srbije. </li>
                    <br /> <li>Svi ugovori sklopljeni putem Eva aplikacije podložni su zakonima Republike Srbije uključujući Zakon o obligacionim odnosima, Zakon o elektronskoj trgovini i Zakon o zaštiti potrošača.</li>
                    <br /> <li>Klijenti i pružaoci psihološke podrške dužni su da se pridržavaju svih važećih pravnih i regulatornih normi tokom korišćenja Eva aplikacije.</li>
                    <br /><li>Svi sporovi nastali između pružaoca psihološke podrške i klijenta rešavaju se isključivo između tih lica.</li>
                </ul><br />
                <h2 id="4-odricanje-od-odgovornosti-">4. Odricanje od odgovornosti:</h2>
                <ul><br />
                    <li><strong>Eva platforma</strong> zadržava pravo da u bilo kom trenutku prekine sa radom platforme.</li>
                    <br /><li><strong>Eva platforma ne snosi odgovornost</strong> za bilo kakve direktne ili indirektne štete nastale korišćenjem naših usluga. </li>
                    <br /><li><strong>Svi korisnici koriste Eva aplikaciju na sopstvenu odgovornost.</strong> </li>
                    <br /><li><strong>Eva ne garantuje</strong> kontinuiranu i neprekinutu dostupnost usluga i može privremeno obustaviti rad aplikacije zbog održavanja, tehničkih problema ili drugih razloga.</li>
                    <br /><li><strong>Eva nije odgovorna</strong> za kvalitet i ishod psihološke podrške pružene putem aplikacije, kao ni za istinitost informacija koje pružaoci psihološke podrške oglase niti za njihovu sposobnost pružanja psihološke podrške.</li>
                    <br /><li><strong>Pružalac psihološke podrške i klijent</strong> dužni su da vode računa o starosnoj dobi klijenta u skladu sa navedenim odredbama ovog dokumenta.</li>
                    <br /><li><strong>Eva platforma i pružaoci psihološke podrške</strong> nisu odgovorni za odluke i postupke klijenta, već je isključivo klijent odgovoran za njih.</li>
                    <br /><h2 id="5-zavr-ne-odredbe">5. Završne odredbe</h2>
                </ul><br />
                <h3 id="5-1-na-in-izmene-uslova">5.1 Način izmene uslova</h3>
                <br /><p>Eva zadržava pravo da menja ove Opšte uslove korišćenja i poslovanja u bilo kom trenutku. Korisnici će biti obavešteni o svim promenama putem e-mail adrese i obaveštenja unutar aplikacije najmanje 14 dana pre nego što promene stupe na snagu.</p>
                <br /><p>Nastavak korišćenja Eva aplikaciju nakon što promene stupe na snagu smatraće se prihvatanjem izmenjenih uslova.</p>
                <br /><h3 id="5-2-kontakt-informacije-za-dodatna-pitanja-ili-pomo-">5.2 Kontakt informacije za dodatna pitanja ili pomoć</h3>
                <br /><p>Za sva pitanja, dodatne informacije ili pomoć korisnici nas mogu kontaktirati na:</p>
                <ul><br />
                    <br /><li>e-mail: evawellbeing@gmail.com .</li>
                </ul><br />
                <h3 id="5-3-zaklju-ak">5.3 Zaključak</h3>
                <br /><p>Samim korišćenjem naše aplikacije slažete se sa ovim dokumentom Opšti uslovi korišćenja i poslovanja i našom Politikom privatnosti.</p>
                <br /> <p>Ako se registrujete na Eva aplikaciji ili nam se obratite putem e-pošte, slažete se da Vašu e-mail adresu možemo koristiti kao legitiman način komunikacije sa Vama i slanja ugovora, obaveštenja i drugih informacija u vezi s Eva platformom. </p>

            </main>
            
            <SimpleDialog isOpenSimpleDialog={isOpenSimpleDialog} handleCloseSimpleDialog={handleCloseSimpleDialog}>
                <p style={{ fontSize: '0.9rem', color: 'hsla(0, 0%, 20%, 1)', fontWeight: 400, }}>Ukoliko obrišeš svoj profil svi tvoji podaci koje si ostavio na EVA aplikaciji će biti <b>trajno obrisani.</b></p>
                <br></br>
                <p
                    className="hoverablePrivacyPoliceButton" style={{
                    marginTop: "0.5rem",
                    fontSize: "0.9rem",
                    color: "hsla(174, 40%, 63%, 1)",
                    borderBottom: "0.1rem solid hsla(0, 0%, 85%, 1)",
                    paddingBottom: "0.3rem",
                    cursor: "pointer",
                    display: 'flex',
                    alignItems: 'center', // Aligns the SVG icon with the text
                }}>
                    
                    Obriši svoj profil
                </p>
                <></>
            </SimpleDialog>
        </div>
    );
}

export default PrivacyPolice;
