import React from "react";
import "./MiniHeader.css";
import { NavLink } from "react-router-dom";
import { useStateValue } from "../StateProvider";
import { useParams } from "react-router-dom";

function MiniHeader(props) {
  const [{ basket, spec }] = useStateValue();
  const profileName = props.myProfile ? basket[0]?.name + '.' + basket[0].surname : props.name;
  const profileNavName = profileName.toLowerCase();
  const { name } = useParams();


  return (
    <nav className="MiniHeader">
      <NavLink 
        to={`/profile/${profileNavName}`} 
        className={({ isActive }) => isActive ? "active" : ""}
        end
      >
        Informacije
      </NavLink>

      {basket[0]?.userType === 'client' && props.myProfile || props.profileUserType === "client" ? (
        props.myProfile ? <NavLink to={`/profile/${profileNavName}/appointments`}>
          Termini
        </NavLink> : null
      ) : (
        <>
          <NavLink to={`/profile/${profileNavName}/ratings`}>
            Ocene
          </NavLink>
          {/* <NavLink to={`/profile/${profileNavName}/gallery`}>
            Galerija
          </NavLink> */}

          {props.myProfile ? <NavLink className='appoNav' to={`/profile/${profileNavName}/appointments`}>
            Termini
          </NavLink> : null}
        </>
      )}
    </nav>
  );
}

export default MiniHeader;
