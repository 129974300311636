import './Footer.css'
import { ReactComponent as LogoSvg2 } from '../../SVGs/Logo2.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <footer className='Footer' role="contentinfo">
            <LogoSvg2 className='LogoFooter'></LogoSvg2>
            <div className='icons-div'>
                <a href="https://www.instagram.com/evawellbeing/" aria-label="Instagram" target="_blank" rel="noopener noreferrer" className='instagram-icon'>
                    <FontAwesomeIcon icon={faInstagram} size="2x" />
                </a>
                <a href="mailto:evawellbeing@gmail.com" aria-label="Gmail" className='gmail-icon'>
                    <FontAwesomeIcon icon={faEnvelope} size="2x" />
                </a>

                <a href="https://evawellbeing.org/" aria-label="Website" target="_blank" rel="noopener noreferrer" className='website-icon'>
                    <FontAwesomeIcon icon={faGlobe} size="2x" />
                </a>
            </div>    
            <div className='legal' aria-label="Legal">
              <a href="https://evawellbeing.app/terms-and-conditions/" aria-label="Terms and Conditions" className='FooterA'>Terms and Conditions</a>
                <a href="https://evawellbeing.app/privacy-policy/" aria-label="Privacy Policy" className='FooterA'>Privacy policy</a>
            </div>
        </footer>
    )
}

export default Footer;