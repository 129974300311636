const PopUp2 = (props) => {

    const {isOpen2, handleClose2, input, setInput, description, setDescription} = props;
  
    const handleChange = (setter) => (e) => setter(e.target.value);
  
    return isOpen2 ? (
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.7)'
      }}>
        <div style={{
          padding: '20px',
          backgroundColor: 'hsla(185, 33%, 40%, 1)',
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <textarea 
            maxLength={130}
            className='PopUpTextarea'   
            placeholder="Dodaj svoj kratak opis(max:130 karaktera)" 
            rows={5}  
            style={{resize: 'none'}}
            value={description} onChange={handleChange(setDescription)
            
            }
          />
          <button className='PopUpButton' onClick={handleClose2} style={{ alignSelf: 'flex-end', marginTop: '10px' }}>Snimi</button>
        </div>
      </div>
    ) : null;
  };
  
  export default PopUp2;
  