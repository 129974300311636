import './PsyhotherapistSingUp.css';
import { Link } from "react-router-dom";

const PsyhotherapystSingUp = ({ privacyPoliceAccepted, setPrivacyPoliceAccepted, showPassword, showPasswordImage, hidePasswordImage, togglePasswordVisibility, adress, setAdress, email, setEmail, password, setPassword, name, setName, surname, setSurname, termsAndConditionAccepted, setTermsAndConditionAccepted }) => {
  

  return (
    <div className="InputsDiv3">
      <input className="Inputs" placeholder="ime" value={name} onChange={(e) => setName(e.target.value)} />
      <input className="Inputs" placeholder="prezime" value={surname} onChange={(e) => setSurname(e.target.value)} />
      <input className="Inputs" type='email' placeholder="e-mail adresa" value={email} onChange={(e) => setEmail(e.target.value)} />
      <div className='PasswordDiv' style={{display: 'flex', width:"100%" }} >
          <input  className='Input' type={showPassword ? "text" : "password"} placeholder="lozinka" autocomplete="new-password" onChange={(e) => setPassword(e.target.value)}/>
          <img style={{position:'relative', right:'2rem'}} width="20rem" src={showPassword ? showPasswordImage : hidePasswordImage} className='passwordButton' onClick={togglePasswordVisibility}/>
      </div>
      <label className='privacyPolicyLabel'>
        Prihvatam <Link to={'../privacy-policy'}>politiku privatnosti</Link> &nbsp;
        <input
          className='privacyPolicyCheckBox'
          name="newsletter"
          type="checkbox"
          onChange={() => { setPrivacyPoliceAccepted(prev => !prev); }}
          value={privacyPoliceAccepted}
        />
      </label>
      <label className='privacyPolicyLabel'>
        Prihvatam <Link to={'../terms-and-conditions'}>uslove korišćenja</Link>  &nbsp;&nbsp;
        <input
          name="newsletter"
          type="checkbox"
          className='privacyPolicyCheckBox'
          onChange={() => { setTermsAndConditionAccepted(prev => !prev); }}
          value={termsAndConditionAccepted}
        />
      </label>
      
      {/* <input className="Inputs" placeholder="broj telefona" value={phone} onChange={(e) => setPhone(e.target.value)} /> */}
    </div>
    )
  }
export default PsyhotherapystSingUp;