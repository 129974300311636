import './AppointmentsSetting.css'
import Header from '../Header/Header';
import ArrowButton from '../ArrowButton/ArrowButton';
import Alert from '../Allert/Alert';
import Hours from '../Hours/Hours';
import TherapyFile from '../TherapyType/TherapyFile';
import { useStateValue } from '../StateProvider';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { auth } from '../../config/firebase-config';
import Footer from '../Footer/Footer';


function AS(props) {

   const [{basket}] = useStateValue();
    const navigate = useNavigate();
     
    useEffect(() => {
        if (auth.currentUser && !basket[0]?.privacyPoliceAccepted) {
            navigate('/terms-and-conditions')
            alert('Da biste koristili aplikaciju kao prijavljen korisnik potrebno je da prihvatite politiku privatnosti.')

        } else if (auth.currentUser && !basket[0]?.termsAndConditionAccepted) {
            navigate('/privacy-policy')
            alert('Da biste koristili aplikaciju kao prijavljen korisnik potrebno je da prihvatite uslove korišćenja.')
        }
    }, [])

    return (
        <div className="AS">
            <Header name={basket[0].name} surname={basket[0].surname} />
            <div className='ASContainer'>
                <div className="MiniAppoContainer">
                <ArrowButton text='Podešavanje' onClickEdit = {() => {navigate(`../profile/${basket[0].name.toLowerCase()}.${basket[0].surname.toLowerCase()}`)}} />
                <Alert text='Dani i termini u kojima radite se odnose na vaše uobičajno radno vreme.
                    Kasnije u “Organizacija termina” sekciji možete odrediti koje termine želite da radite
                    za svaki dan pojedinačno.'
                    backgroundColor='hsla(174, 40%, 63%, 0.2)'
                    width='100%'
                    signColor='hsla(174, 40%, 63%, 1)'
                    textColor='hsla(219, 16%, 35%, 1)' />
                <p className='ASText'>Odaberi dane kojima želiš radiš</p>
                <div className='DayPicker'>
                    <div className='DayPickerBox'>
                        <div className='Day' >PON</div>
                        <div className='CheckBoxA' onClick={(e) => props.setCheckBox(e, 0)}>{props.checkBoxesState[0] === 0 ? '\u2713' : null}</div>

                    </div>
                    <div className='DayPickerBox'>
                        <div className='Day'>UTO</div>
                        <div className='CheckBoxA' onClick={(e) => props.setCheckBox(e, 1)}>{props.checkBoxesState[1] === 0 ? '\u2713' : null}</div>
                    </div>
                    <div className='DayPickerBox'>
                        <div className='Day'>SRE</div>
                        <div className='CheckBoxA' onClick={(e) => props.setCheckBox(e, 2)}>{props.checkBoxesState[2] === 0 ? '\u2713' : null}</div>
                    </div>
                    <div className='DayPickerBox'>
                        <div className='Day'>ČET</div>
                        <div className='CheckBoxA' onClick={(e) => props.setCheckBox(e, 3)}>{props.checkBoxesState[3] === 0 ? '\u2713' : null}</div>
                    </div>
                    <div className='DayPickerBox'>
                        <div className='Day'>PET</div>
                        <div className='CheckBoxA' onClick={(e) => props.setCheckBox(e, 4)}>{props.checkBoxesState[4] === 0 ? '\u2713' : null}</div>
                    </div>
                    <div className='DayPickerBox'>
                        <div className='Day'>SUB</div>
                        <div className='CheckBoxA' onClick={(e) => props.setCheckBox(e, 5)}>{props.checkBoxesState[5] === 0 ? '\u2713' : null}</div>
                    </div>
                    <div className='DayPickerBox'>
                        <div className='Day'>NED</div>
                        <div className='CheckBoxA' onClick={(e) => props.setCheckBox(e, 6)}>{props.checkBoxesState[6] === 0 ? '\u2713' : null}</div>
                    </div>
                </div>
                <p className='ASText'><p className='ASText'>Odaberi termine kojima želiš da radiš</p></p>
                <Hours setCheckBox={props.setCheckBox} hoursArray={props.hoursArray} width='100%' />
                {/* <p className='ASText'> Odaberi način držanja seansi &#40;moguće je odabrati obe opcije&#41;</p>
                <TherapyFile  setCheckBox={props.setCheckBox} />
                <p className='ASText'>Vaša Adresa</p>
                <input className='SettingsInput' type='text' value={props.address} onChange={(e) => props.setAddress(e.target.value)} autoComplete='true' placeholder='Požeška 23/3, 11030, Beograd' required></input> */}
                <p className='ASText'>Vaš račun (ukoliko ne planirate da naplaćujete stavite <strong>/</strong>) </p>
                
                <input 
                    className='SettingsInput' 
                    type='text' 
                    value={props.account} 
                    onChange={(e) => props.setAccount(e.target.value)} 
                    autoComplete='true' 
                    placeholder='000-0000000000000-00'
                />
                <p className='ASText'>IBAN (<strong>Opciono!</strong> Ukoliko želite da naplaćujete klijentima iz drugih zemalja.) </p>
                
                <input 
                    className='SettingsInput' 
                    type='text' 
                    value={props.account2} 
                    onChange={(e) => props.setAccount2(e.target.value)} 
                    autoComplete='true' 
                    placeholder='RS35160005080002381269'	 
                    
                />
                <p className='ASText'>Opis plaćanja (<strong>Opciono!</strong> Ovaj opis će videti klijent koji je zakazao termin kod Vas. max: 350 karaktera) </p>
                <textarea 
                value={props.description}
                onChange={(e) => props.setDescription(e.target.value)}
                className='SettingsInput'
                maxLength='350'  
                placeholder='Primer: Uplatu kod mene možete izvršiti putem PayPal-a. Prvi termin je besplatan.'
                              >

                </textarea>
                <p className='ASText' >Cena (u dinarima) </p>
                <input 
                    className='SettingsInput' 
                    type="number" 
                    step="100" 
                    placeholder='3000' 
                    value={props.price} 
                    onChange={(e) => props.setPrice(e.target.value)}  
                />
                <button className="submitButtonAS" onClick={props.submit}>Sačuvaj izmene</button>
                
                </div>
                <Footer />
            </div>
        </div>
    )
}
export default AS;