import React from 'react';

const InstructionDialog = (props) => {
  const {isOpen, handleClose} = props;

  return isOpen ? (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0,0,0,0.5)',
    }}>
      <div style={{
        padding: '20px',
        position: 'relative', // This creates a positioning context for the close button
        backgroundColor: '#fff',
        borderRadius: '10px',
        width: '80%',
        maxWidth: '600px',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        maxHeight: '90%',
      }}><h2>Uputstvo za registraciju</h2> {/* Header */}

      <br/>
       <p>
             Možete se registrovati kao <b>klijent</b>, <b>terapeut</b>, <b>savetnik</b> ili <b>ponuđač razgovora</b>.
          </p>
          <br/>
          <h3>Klijent:</h3>
          <br/>
          <p>Možete tražiti psihološku podršku, ali je ne možete nuditi. Možete podesiti svoj profil i ubaciti osnovne informacije o sebi. Informacijama sa Vašeg profila može pristupiti isključivo ponuđač kod kog ste zakazali seansu. Bićete primljeni na aplikaciju u momentu registracije, nije potrebno dostavljati dodatne informacije.</p>
          <br/>
          <h3>Razgovor:</h3>
          <br/>
          <p>Možete nuditi psihološku podršku u kategoriji „Razgovori” i možete tražiti psihološku podršku. Ukoliko postavite svoj profil na Feed, informacije o Vašem profilu mogu videti svi korisnici. Nakon registracije u roku od 12h biće Vam poslat mejl na koji je potrebno odogovoriti slanjem kratke biografije. Nakon pregleda biografije, pristup aplikaciji biće Vam odobren ili odbijen oko čega ćete biti obavešteni putem mejla. </p>
          <br/>
          <h3>Savetnik:</h3>
          <br/>
          <p>Da biste se prijavili za ovu kategoriju, potrebno je da budete na minimum 3. godini studija psihologije; ili da imate završen fakultet ili višu školu na smeru bliskom psihologiji; ili da ste imali iskustvo rada u oblasti psihološke pomoći. Bićete raspoređeni u kategoriju „EVA Savetnici” i pored toga što možete nuditi psihološku podršku, možete je i tražiti. Ukoliko postavite svoj profil na Feed, informacije o Vašem profilu mogu videti svi korisnici. Nakon registracije u roku od 12h biće Vam poslat mejl na koji je potrebno odgovoriti slanjem sertifikata, diploma ili sličnih dokaza da imate odgovarajuće kvalifikacije. Nakon pregleda dokumentacije, pristup aplikaciji biće Vam biti odobren ili odbijen oko čega ćete biti obavešteni putem mejla. </p>
          <br/>
          <h3>Terapeut:</h3>
          <br/>
          <p>Da biste se prijavili za ovu kategoriju, potrebno je da ste psihoterapeut ili psihološki savetnik što znači da imate sertifikat za psihoterapeuta ili psihološkog savetnika; ili da ste završili studije psihologije. Bićete raspoređeni u kategoriju „Psihoterapeuti i Savetnici” i pored toga što možete nuditi psihološku podršku, možete je i tražiti. Ukoliko postavite svoj profil na Feed, informacije o Vašem profilu mogu videti svi korisnici. Nakon registracije u roku od 12h biće Vam poslat mejl na koji je potrebno odogovoriti slanjem sertifikata i/ili diploma. Nakon pregleda svega što ste nam poslali, pristup aplikaciji će Vam biti odobren ili odbijen oko čega ćete biti obavešteni putem mejla. </p>
      <p>
        
      </p>
      
      <button onClick={handleClose} style={{
      
        marginTop: '20px',
        alignSelf: 'flex-end',
        padding: '10px 20px',
        backgroundColor: '#5ea9a1',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
      }}>
        Izađi
      </button>
        {/* Close button relative to the dialog box */}
        <button onClick={handleClose} style={{
          position: 'absolute', // Positioned absolutely relative to its nearest positioned ancestor (the dialog box)
          top: '10px', // Distance from the top edge of the dialog box
          right: '10px', // Distance from the right edge of the dialog box
          padding: '5px',
          backgroundColor: 'white', // Light gray background for visibility
          color: '#000', // Black color for the "X"
          fontSize: '24px', // Larger font size for the "X"
          border: 'none',
          cursor: 'pointer',
        }}>
          &times; {/* HTML entity for the multiplication sign, used as a close icon */}
        </button>

        
      </div>
    </div>
  ) : null;
};

export default InstructionDialog;
