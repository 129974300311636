import './ClientSignUp.css';
import { Link } from "react-router-dom";

const ClientSignUp = ({ privacyPoliceAccepted, setPrivacyPoliceAccepted, showPassword, showPasswordImage, hidePasswordImage, togglePasswordVisibility, email, setEmail, password, setPassword, name, setName, surname, setSurname, termsAndConditionAccepted, setTermsAndConditionAccepted}) => {
return (
<div className = 'InputsDiv2'>
<input className='Input' placeholder="ime" value={name} onChange={(e) => setName(e.target.value)}/>
<input className='Input' placeholder="prezime" value={surname} onChange={(e) => setSurname(e.target.value)}/>
<input className='Input' type='email' placeholder="e-mail adresa" value={email} onChange={(e) => setEmail(e.target.value)}/>
<div style={{display: 'flex' }} >
          <input  className='Input' type={showPassword ? "text" : "password"} placeholder="lozinka" autocomplete="new-password" onChange={(e) => setPassword(e.target.value)}/>
          <img style={{position:'relative', right:'2rem'}} width="20rem" src={showPassword ? showPasswordImage : hidePasswordImage} className='passwordButton' onClick={togglePasswordVisibility}/>
        </div>
        <label className='privacyPolicyLabel'>
            Prihvatam <Link to={'../privacy-policy'}>politiku privatnosti</Link> &nbsp;
            <input
                name="newsletter"
                type="checkbox"
                className='privacyPolicyCheckBox'
                onChange={() => {setPrivacyPoliceAccepted(prev => !prev);}}
                value={privacyPoliceAccepted}
            />
        </label>
        <label className='privacyPolicyLabel'>
            Prihvatam <Link to={'../terms-and-conditions'}>uslove korišćenja</Link> &nbsp;&nbsp;
            <input
                name="newsletter"
                type="checkbox"
                className='privacyPolicyCheckBox'
                onChange={() => {setTermsAndConditionAccepted(prev => !prev);}}
                value={termsAndConditionAccepted}
            />
        </label>
</div>
 )}
export default ClientSignUp;