    import "./RatingsList.css"
    import { ReactComponent as RatingsSvg } from '../../SVGs/Raitings.svg';
    import { ReactComponent as Star2Svg } from '../../SVGs/Star2.svg';
    import { useStateValue } from "../StateProvider";
    import image from '../Card/Iva 2.jpg' 
    import { useEffect, useState } from "react";
    import RaitingDialog from '../RaitingsDialog/RaitingsDialog'
    import {where, getDocs, collection, query, onSnapshot, doc, setDoc,limit,startAfter} from "firebase/firestore";
    import { auth,db } from "../../config/firebase-config";
    import { useParams } from "react-router-dom";
    import image2 from '../ProfileCard/Default_pfp.jpg'

    function RatingsList() {
        const [isRatingDialogOpen,setIsRatingDialogOpen] = useState(false);
        const [ratings,setRatings] = useState([]);
        const [lastVisible, setLastVisible] = useState(null);
        const [ratingsRef, setRatingsRef] = useState(null);
        const [isMoreAvailable, setIsMoreAvailable] = useState(true);

const { name } = useParams();
const [{basket,spec , id, user, onHomeState, headerName}, dispatch] = useStateValue();
const [clicked,setClicked] = useState(0);
const basketName2 = basket && basket[0] ? basket[0].name + '.' + basket[0].surname : '';
const nameTrimmed = name.trim();
const basketName2Trimmed = decodeURIComponent(basketName2.toLowerCase().trim());
        const myProfile = nameTrimmed === basketName2Trimmed;
function capitalizeFirstLetterOfEachWord(str) {
    return str.toLowerCase().split(' ').map(function (word) {
    return word.charAt(0).toUpperCase() + word.substring(1);
            }).join(' ');
        }
        

const onClose2 = ()=>{
    setIsRatingDialogOpen(false);
}

        const getClients = async() => {
            let clientId;

            if (!myProfile && !spec.id) {
                const nameDecode = decodeURIComponent(name);
                const noDotName = nameDecode.replace('.', ' ');
                const fullName = capitalizeFirstLetterOfEachWord(noDotName)
                const nameParts = fullName.split(' ');
                const otherUserName = nameParts[0]; // The first word is the first name
                let otherUserSurname = '';

                if (nameParts.length > 1) {
                    // Join the remaining parts as the surname
                    otherUserSurname = nameParts.slice(1).join(' ');
                }
                
                const q = query(
                    collection(db, 'Clients'),
                    where('name', '==', otherUserName),
                    where('surname', '==', otherUserSurname)
                );
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    clientId = doc.id
                });
            } else {
                clientId = myProfile ? auth.currentUser.uid : spec.id;
            }
            const ratingsRef = collection(db, "Clients", clientId, "Ratings");
            setRatingsRef(ratingsRef);
            const q = query(ratingsRef, limit(2)); // Adjust limit as needed
            getDocs(q).then(querySnapshot => {
              let totalRating = 0;
              let totalReviews = querySnapshot.size;
              const ratingsArray = querySnapshot.docs.map(doc => {
                const data = doc.data();
                totalRating += data.rating;
                return { id: doc.id, ...data };
              });
          
              /* const averageRating = spec.averageRating;
              setDoc(doc(db, "Clients", clientId), { averageRating: averageRating }, { merge: true }); */
              setRatings(ratingsArray);
              const lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
              setLastVisible(lastVisibleDoc);
              setIsMoreAvailable(querySnapshot.docs.length >= 2);
            });
          };
          
        useEffect(() => {
               
            getClients();
            
    }, []);

        function ShowRatings() {
            return ratings.map((rating) => {
                return (
                    <div className="RatingsBox" key={rating.id}>
                        <div className="UpperRatingsBox">
                            <div className='LeftUpperRatingsBox'>
                                <div className="RatingsImageContainer">
                                    <img className="RatingsImage" src={rating.img === '' ? rating.img : image2} alt="rating"></img>
                                </div>
                                <div className="RatingsNameDate">
                                    <div className="RatingsName">{rating.id}</div>
                                    <div className="RatingsDate">
  {rating.date && rating.date.seconds ? new Date(rating.date.seconds * 1000).toLocaleString('sr-Latn-RS') : '   '}
</div>
                                </div>
                            </div>
                            <div className='RightUpperRatingsBox'>
                                <Star2Svg />
                                <div className="RatingsNumber">{parseFloat(rating.rating).toFixed(1)}</div>
                            </div>
                        </div>
                        <div className="LowerRatingsBox">
                            <p className='RatingsComent'>{rating.review}</p>
                        </div>
                    </div>
                )
            })
        }
        const loadMoreRatings = () => {
            if (!lastVisible) return;
          
            const nextQuery = query(ratingsRef, startAfter(lastVisible), limit(2));
            getDocs(nextQuery).then(querySnapshot => {
              const newRatings = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
              setRatings(prevRatings => [...prevRatings, ...newRatings]);
              const newLastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
              setLastVisible(newLastVisible);
              setIsMoreAvailable(querySnapshot.docs.length >= 2);
            });
          };
          
    
        
        return (
            <div className = "RatingsList">
                <div className= "RatingsHeader">
                    <RatingsSvg></RatingsSvg>
                    <span className="RatingsHeaderText"> Rejtinzi i komentari ({ratings.length})</span>
                </div>
                {myProfile || !auth.currentUser ? null : <div className='addRating' onClick={()=>{setIsRatingDialogOpen(true)}}>Dodaj rejting +</div>}
                
                {ShowRatings()}
                <button onClick={loadMoreRatings} className="LoadMoreButton">Učitaj još</button>
                
                
                
                <RaitingDialog clicked = {clicked} setClicked={setClicked} isOpen={isRatingDialogOpen} onClose2={onClose2} />   
            </div>
        )
    }
    export default RatingsList ;